// THIS FILE IS AUTOGENERATED

/* eslint-disable max-lines */

import { makeServerTranslations } from '~shared/utils/makeServerTranslations';

export type CourierStatuses = 'active' | 'disabled' | 'removed';
export const courierStatusesValues = ['active', 'disabled', 'removed'] as CourierStatuses[];
export const courierStatusesNames = makeServerTranslations(courierStatusesValues, 'enums.COURIER_STATUSES');

export type OrderProviders = 'eda' | 'lavka';
export const orderProvidersValues = ['eda', 'lavka'] as OrderProviders[];
export const orderProvidersNames = makeServerTranslations(orderProvidersValues, 'enums.ORDER_PROVIDERS');

export type SupplyDeliveryTypes = 'foot' | 'car' | 'rover' | 'yandex_taxi' | 'yandex_taxi_remote' | 'yandex_taxi_night';
export const supplyDeliveryTypesValues = [
	'foot',
	'car',
	'rover',
	'yandex_taxi',
	'yandex_taxi_remote',
	'yandex_taxi_night',
] as SupplyDeliveryTypes[];
export const supplyDeliveryTypesNames = makeServerTranslations(
	supplyDeliveryTypesValues,
	'enums.SUPPLY_DELIVERY_TYPES'
);

export type SupplyTypes = 'courier' | 'collector';
export const supplyTypesValues = ['courier', 'collector'] as SupplyTypes[];
export const supplyTypesNames = makeServerTranslations(supplyTypesValues, 'enums.SUPPLY_TYPES');

export type GroceryShiftStatuses = 'open' | 'pause' | 'unpause' | 'close';
export const groceryShiftStatusesValues = ['open', 'pause', 'unpause', 'close'] as GroceryShiftStatuses[];
export const groceryShiftStatusesNames = makeServerTranslations(
	groceryShiftStatusesValues,
	'enums.GROCERY_SHIFT_STATUSES'
);

export type EdaCourierTransportTypes = 'bicycle' | 'electric_bicycle' | 'motorcycle' | 'pedestrian' | 'vehicle';
export const edaCourierTransportTypesValues = [
	'bicycle',
	'electric_bicycle',
	'motorcycle',
	'pedestrian',
	'vehicle',
] as EdaCourierTransportTypes[];
export const edaCourierTransportTypesNames = makeServerTranslations(
	edaCourierTransportTypesValues,
	'enums.EDA_COURIER_TRANSPORT_TYPES'
);

export type EdaCourierStatus =
	| 'active'
	| 'blocked'
	| 'candidate'
	| 'deactivated'
	| 'ill'
	| 'inactive'
	| 'lost'
	| 'on_vacation';
export const edaCourierStatusValues = [
	'active',
	'blocked',
	'candidate',
	'deactivated',
	'ill',
	'inactive',
	'lost',
	'on_vacation',
] as EdaCourierStatus[];
export const edaCourierStatusNames = makeServerTranslations(edaCourierStatusValues, 'enums.EDA_COURIER_STATUS');

export type FinancialRelationshipTypes = 'courier_service' | 'self_employed' | 'direct_cooperation';
export const financialRelationshipTypesValues = [
	'courier_service',
	'self_employed',
	'direct_cooperation',
] as FinancialRelationshipTypes[];
export const financialRelationshipTypesNames = makeServerTranslations(
	financialRelationshipTypesValues,
	'enums.FINANCIAL_RELATIONSHIP_TYPES'
);

export type Patents = 'moscow' | 'moscow_region';
export const patentsValues = ['moscow', 'moscow_region'] as Patents[];
export const patentsNames = makeServerTranslations(patentsValues, 'enums.PATENTS');

export type CourierBlockSources = 'wms' | 'eda' | 'taxi';
export const courierBlockSourcesValues = ['wms', 'eda', 'taxi'] as CourierBlockSources[];
export const courierBlockSourcesNames = makeServerTranslations(
	courierBlockSourcesValues,
	'enums.COURIER_BLOCK_SOURCES'
);

export type CourierBriefStatus = 'active' | 'disabled' | 'removed';
export const courierBriefStatusValues = ['active', 'disabled', 'removed'] as CourierBriefStatus[];
export const courierBriefStatusNames = makeServerTranslations(courierBriefStatusValues);

export type CourierBriefGroceryShiftStatus = 'open' | 'pause' | 'unpause' | 'close';
export const courierBriefGroceryShiftStatusValues = [
	'open',
	'pause',
	'unpause',
	'close',
] as CourierBriefGroceryShiftStatus[];
export const courierBriefGroceryShiftStatusNames = makeServerTranslations(courierBriefGroceryShiftStatusValues);

export type CourierCheckinInfoGroceryShiftStatus = 'open' | 'pause' | 'unpause' | 'close';
export const courierCheckinInfoGroceryShiftStatusValues = [
	'open',
	'pause',
	'unpause',
	'close',
] as CourierCheckinInfoGroceryShiftStatus[];
export const courierCheckinInfoGroceryShiftStatusNames = makeServerTranslations(
	courierCheckinInfoGroceryShiftStatusValues
);
