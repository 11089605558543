import type { ComponentProps, MouseEvent, MouseEventHandler } from 'react';
import { Link as BaseLink } from 'react-router-dom';

// Отказались от заворачивания в new URL из-за риска ошибки конструирования URL
export const tgUrl = (value: string) => `https://t.me/${value}`;
export const emailUrl = (value: string) => `mailto:${value}`;

export type AdditionalProps = {
	metrikaVars?: Record<string, unknown>;
	onLeftClick?: MouseEventHandler<HTMLAnchorElement> | undefined;
	outer?: boolean;
	isMouseDown?: boolean;
};

export function Link(props: ComponentProps<typeof BaseLink> & AdditionalProps) {
	const { onClick, outer, onLeftClick, isMouseDown = false, ...restProps } = props;

	const outerProps = {
		rel: 'noopener noreferrer',
		target: '_blank',
	};

	const handleClick = (e: MouseEvent<HTMLAnchorElement>) => {
		onClick?.(e);

		if (onLeftClick) {
			if (e.button !== 0 || e.ctrlKey || e.metaKey || e.shiftKey) {
				return;
			}
			e.preventDefault();

			onLeftClick(e);
		}
	};

	const linkProps = outer ? { ...restProps, ...outerProps } : { ...restProps };

	return (
		<BaseLink
			{...linkProps}
			onClick={handleClick}
			onMouseDown={
				isMouseDown
					? (event) => {
							if (event.button === 1) {
								window.open(String(props.to), '_blank');
							}
						}
					: undefined
			}
		/>
	);
}
