import { Col, Row, Select, Tooltip } from 'antd';
import { apiLoadProperties } from 'multiSelect/initMultiSelect';
import type { Dispatch, SetStateAction } from 'react';
import { useEffect } from 'react';

import type { EntityName } from '~cache/apiLoadProperties';
import { useCache } from '~cache/useCache';
import { ReactComponent as TooltipIcon } from '~images/question-circle-solid.svg';
import type { SelectFieldProps } from '~shared/components/Fields/SelectField';
import { useStyles } from '~shared/components/Fields/styles';
import { getColSpan, getViewText, useFieldChangeProps } from '~shared/components/Fields/utils';
import { camelToSnakeCase } from '~shared/utils/camelToSnakeCase';

import { useStyles as ownUseStyles } from './styles';

const { Option } = Select;
const SingleSelect = (
	props: SelectFieldProps & {
		selectEntity: EntityName;
		searchDataProps?: Record<string, string | undefined>;
		setSearchData: Dispatch<SetStateAction<Record<string, string | undefined> | undefined>>;
		dictionary: Record<string, string>;
		fixedOptions?: string[];
		link?: string;
		setDictionary: Dispatch<SetStateAction<Record<string, string>>>;
		tooltip?: string;
	}
) => {
	const { cx, classes } = useStyles();
	const { classes: ownClasses } = ownUseStyles();
	const {
		meta: { error },
		viewValue,
		editMode,
		colspan,
		fieldRequired,
		id,
		input,
		width,
		label,
		disabled,
		selectEntity: entity,
		dictionary,
		loading,
		searchDataProps,
		setSearchData,
		options,
		fixedOptions,
		setDictionary,
		onlyView,
		hasLock,
		disabledOptions,
		resetValue,
		tooltip,
	} = props;

	const dictionaryKeys = Object.keys(dictionary ?? {});
	const optionsToDisplay = fixedOptions ?? (options?.length ? options : dictionaryKeys);
	//@ts-expect-error
	const cache = useCache({
		[entity]: input.value,
	});

	const entityInstance = cache[entity]?.[input.value] ?? {};
	const entityProperties = apiLoadProperties[entity];
	// @ts-ignore
	const title = entityProperties?.title(entityInstance) ?? '';
	const viewTextDictionary = title ? { [input.value]: title } : dictionary;

	useEffect(() => {
		if (title !== '') {
			setDictionary({ ...dictionary, [input.value]: title });
		}
	}, [title]);

	const fieldChangeProps = useFieldChangeProps(
		input,
		disabled || onlyView,
		hasLock && editMode,
		editMode,
		`input lock button ${label}`,
		undefined,
		resetValue
	);

	const renderSelect = (
		<>
			<div className={cx(classes.inputContainer, 'wms-input-container')}>
				<Select
					id={id}
					loading={loading}
					onBlur={() => {
						setSearchData(searchDataProps);
					}}
					data-test={`data form ${label}${fieldRequired ? ' required' : ''}${disabled ? ' disabled' : ''}`}
					allowClear
					className={cx(classes.inputSelect, { [ownClasses.selectDisabled]: disabled, [ownClasses.select]: !disabled })}
					showArrow={true}
					showSearch
					onSearch={(value) => setSearchData({ title: value || undefined })}
					{...fieldChangeProps.changeProps}
					placeholder={props.placeholder}
					popupClassName={`data-test-select-dropdown-${label?.replace(/\s/g, '-')}`}
					// если опции переданы извне, фильтруем их, если нет, отключаем фильтр и будет использоваться onSearch
					filterOption={
						fixedOptions?.length
							? (inputValue, option) => option?.props.children?.toLowerCase().includes(inputValue.toLowerCase())
							: false
					}
				>
					{optionsToDisplay?.map((option) => {
						return (
							<Option
								key={option}
								value={option}
								disabled={disabledOptions?.includes(option)}
								data-test={`data form option select ${dictionary?.[option] || option}${disabledOptions?.includes(option) ? ' disabled' : ''}`}
							>
								{dictionary[option] ?? option}
							</Option>
						);
					})}
				</Select>
				{tooltip && (
					<div className={ownClasses.tooltipContainer}>
						<Tooltip overlayInnerStyle={{ padding: 16 }} title={tooltip}>
							<TooltipIcon className={cx(classes.labelTooltip, ownClasses.tooltipIcon)} data-test="tooltip icon" />
						</Tooltip>
					</div>
				)}
				{editMode && hasLock && fieldChangeProps.lockButton}
			</div>
			{error && (
				<span className={cx({ [classes.inputTextError]: error })} data-test={`data form ${label} error`}>
					{error}
				</span>
			)}
		</>
	);

	if (props.customLayout) {
		return props.customLayout(label, renderSelect);
	}

	return (
		<>
			<Row align="top" gutter={[0, { xs: 0, sm: editMode ? 10 : 20 }]} style={{ inlineSize: width }}>
				<Col {...getColSpan(colspan)} className={classes.labelContainer}>
					<label
						className={cx(classes.label, {
							[classes.labelRequired]: editMode && fieldRequired,
						})}
						htmlFor={id}
					>
						{label}
					</label>
					<div className={classes.dotSeparator} />
				</Col>
				{
					<Col {...getColSpan(colspan)}>
						{editMode && !onlyView && renderSelect}
						{(onlyView || !editMode) && (
							<div data-test={`data form ${label}`}>
								{getViewText({
									value: viewValue ?? input.value,
									dictionary: viewTextDictionary,
									path: camelToSnakeCase(entity),
									loading,
								})}
							</div>
						)}
					</Col>
				}
			</Row>
		</>
	);
};

export default SingleSelect;
