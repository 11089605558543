import type { IsoLanguage } from './types';

export const languages = ['ru_RU', 'en_US', 'fr_FR', 'he_IL', 'ar_SA', 'uz_UZ', 'tr_TR', 'es_419', 'kk_KZ'] as const;

export const isoLanguages: IsoLanguage[] = languages.map((lang) => lang.replace('_', '-') as IsoLanguage);

export const languageNames = (lang: string): string => {
	lang = lang.replace('_', '-');
	const languageName = new Intl.DisplayNames([lang], { type: 'language', fallback: 'code' }).of(lang.slice(0, 2));
	return (languageName?.charAt(0)?.toUpperCase() ?? '') + (languageName?.slice(1) ?? '');
};

export const languagesNames = (customLanguages?: string[]) =>
	(customLanguages ?? languages).reduce(
		(prev, cur) => ({
			...prev,
			[cur]: languageNames(cur),
		}),
		{}
	);
