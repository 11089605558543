/* eslint-disable max-lines */

import type { FC } from 'react';
import { useMemo } from 'react';
import type { CSSInterpolation } from 'tss-react';
import { GlobalStyles as TssGlobalStyles } from 'tss-react';

import { useStyles } from '~styles/theme';

import type { Theme } from '../components/Theme';

const styles = (theme: Theme) => ({
	'html, body': {
		color: theme.text.primary,
		'@media print': {
			blockSize: '99%',
		},
	},
	a: {
		color: theme.text.btnLink,
	},
	'.button-icon': {
		padding: 0,
		inlineSize: 32,
		blockSize: 32,
		display: 'flex',
		flexShrink: 0,
		alignItems: 'center',
		justifyContent: 'center',
	},
	'.button-icon-alt': {
		padding: 0,
		inlineSize: 32,
		blockSize: 32,
		flexShrink: 0,
		alignItems: 'center',
		justifyContent: 'center',
		border: 'none',
		opacity: 0.1,
		display: 'inline-flex',
		backgroundColor: 'transparent',
		color: theme.text.secondary,
		'&:hover': {
			opacity: 1,
			color: theme.text.secondary,
			backgroundColor: theme.border.primary,
		},
	},
	'.ant-layout-sider-children': {
		blockSize: '100%',
	},
	'.ant-table-content, .ant-modal-header': {
		backgroundColor: theme.bgColor.header + '!important',
	},
	'.ant-select': {
		inlineSize: '100%',
		'.ant-select-selection-item-remove': {
			color: theme.text.icon,
		},
		'.ant-select-clear': {
			backgroundColor: 'transparent',
			color: theme.bgColor.switch,
		},
	},
	'.ant-table thead > tr > th, .ant-table tbody > tr > td, .ant-table tfoot > tr > th, .ant-table tfoot > tr > td': {
		padding: '11px 16px',
	},
	'.ant-table-tbody > tr.ant-table-placeholder:hover > td': {
		backgroundColor: theme.bgColor.main,
	},
	'.ant-tabs-tab': {
		fontWeight: 500,
		color: theme.text.secondary,
	},
	'.ant-tabs.ant-tabs-left': {
		'& .ant-tabs-tab-active': {
			'& .ant-tabs-tab-btn': {
				fontWeight: 500,
				textShadow: 'none',
			},
		},
		'& .ant-tabs-tab-btn': {
			fontWeight: 500,
		},
	},
	'.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn': {
		color: theme.text.btnLink,
	},
	'.ant-dropdown-trigger .anticon.anticon-search': {
		paddingBlockStart: '50%',
		paddingBlockEnd: '50%',
	},
	'.order-problem-row': {
		'&>td': {
			backgroundColor: theme.bgColor.table.problem,
		},
	},
	'.ant-table-has-fix-left.ant-table-ping-left': {
		'& .ant-table-cell-fix-left': {
			boxShadow: '6px 0 6px -1px rgba(0,0,0,.15)',
			transition: 'box-shadow .3s ease',
		},
	},
	'.ant-table-has-fix-left.ant-table-ping-right': {
		'& .ant-table-cell-fix-right': {
			boxShadow: '-6px 0 6px -1px rgba(0,0,0,.15)',
			transition: 'box-shadow .3s ease',
		},
	},
	'.ant-table-cell-fix-left.ant-table-cell-fix-left-last': {
		backgroundColor: theme.bgColor.main,
	},
	'.ant-table-cell, .ant-table thead .ant-table-cell': {
		backgroundColor: theme.bgColor.main,
		color: theme.text.primary,
		whiteSpace: 'nowrap',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		blockSize: 44,
		boxSizing: 'border-box',
		padding: '0 16px!important',
		'& .ant-input[type="number"]': {
			maxInlineSize: 70,
		},
	},
	'tr.ant-table-expanded-row > td, tr.ant-table-expanded-row:hover > td': {
		backgroundColor: 'inherit',
	},
	'.ant-table-column-sort': {
		backgroundColor: theme.bgColor.sort + '!important',
	},
	'.ant-table-column-sorters': {
		paddingInlineStart: '0!important',
		paddingInlineEnd: '0!important',
	},
	'.ant-layout-sider': {
		backgroundColor: theme.bgColor.sider,
	},
	'.ant-table tbody > tr.ant-table-row': {
		'&.ant-table-row-selected': {
			'& > td': {
				backgroundColor: theme.bgColor.statusBlue,
			},
		},
		'&:hover': {
			'& >td': {
				backgroundColor: theme.bgColor.table.hover,
			},
		},
	},
	'.order-row': {
		cursor: 'pointer',
	},
	'.ant-menu-inline .ant-menu-item, .ant-menu-inline .ant-menu-submenu-title': {
		inlineSize: '100%',
	},
	'.ant-menu': {
		color: theme.text.menuItem,
	},
	'.ant-menu-vertical': {
		marginBlockEnd: 0,
	},
	'.ant-input, .ant-select-selection': {
		backgroundColor: theme.bgColor.main,
		color: theme.text.primary,
		borderRadius: 4,
		borderColor: theme.border.secondary,
	},
	'.ant-input-suffix': {
		color: theme.text.primary,
	},
	'.ant-input-disabled, .ant-select-disabled .ant-select-selection, .ant-select-disabled .ant-select-selector, .ant-picker-disabled':
		{
			backgroundColor: theme.bgColor.disabled + '!important',
			color: theme.text.disabled + '!important',
			borderColor: theme.border.disabled + '!important',
		},
	'.ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector': {
		color: theme.text.disabled + '!important',
	},
	'.ant-select:not(.ant-select-customize-input) .ant-select-selector': {
		borderColor: theme.border.secondary,
	},
	// '.ant-btn:not(.ant-btn-dangerous, .ant-btn-primary, .ant-btn[disabled])': {
	// 	backgroundColor: theme.bgColor.main,
	// 	color: theme.text.primary,
	// 	'&:hover': {
	// 		color: '#40a9ff',
	// 	},
	// },
	'.ant-btn-active': {
		color: '#40a9ff',
		borderColor: '#40a9ff',
	},
	'.ant-btn:not(.ant-btn-link, .ant-btn-primary)': {
		color: theme.text.primary,
		backgroundColor: theme.bgColor.button,
		borderColor: theme.border.secondary,

		'&:hover, &:active, &:focus': {
			backgroundColor: theme.bgColor.main,
		},
		'&[disabled], &[disabled]:hover': {
			color: theme.text.disabled,
			background: theme.bgColor.disabled,
			borderColor: theme.border.secondary,
		},
	},
	'.ant-col, .ant-col > label': {
		color: theme.text.primary,
	},
	'.ant-modal-header': {
		borderBottomColor: theme.border.primary,
		margin: '0px !important',
		'& .ant-modal-title': {
			inlineSize: '100%',
		},
	},
	'.ant-modal-footer': {
		borderTopColor: theme.border.primary,
		paddingInline: '16px !important',
		paddingBlockEnd: '10px !important',
	},
	'.ant-modal-body': {
		overflow: 'auto',
		paddingInline: '15px !important',
		paddingBlockEnd: '15px !important',
	},
	'.ant-modal': {
		color: theme.text.primary,
	},
	'.ant-btn.ant-btn-link': {
		color: theme.text.btnLink,
		backgroundColor: 'transparent',
		padding: 0,
		display: 'inline-block',
		blockSize: 'auto',
	},
	'.ant-btn-primary': {
		color: '#fff',
		textShadow: 'none',
		backgroundColor: '#1890ff',
		'&:hover': {
			color: '#fff',
			backgroundColor: '#40a9ff',
		},
		'&:active': {
			color: '#fff',
			backgroundColor: '#40a9ff',
		},
		'&:focus': {
			color: '#fff',
			backgroundColor: '#40a9ff',
		},
		'&[disabled]': {
			color: theme.text.disabled,
			backgroundColor: theme.bgColor.disabled,
			borderColor: theme.border.disabled,
			'&:hover': {
				color: theme.text.disabled,
				backgroundColor: theme.bgColor.disabled,
				borderColor: theme.border.disabled,
			},
			'&:active': {
				color: theme.text.disabled,
				backgroundColor: theme.bgColor.disabled,
				borderColor: theme.border.disabled,
			},
			'&:focus': {
				color: theme.text.disabled,
				backgroundColor: theme.bgColor.disabled,
				borderColor: theme.border.disabled,
			},
		},
	},
	'.ant-checkbox': {
		insetBlockStart: 0,
	},
	'.ant-btn-danger-disabled, .ant-btn-danger.disabled, .ant-btn-danger[disabled], .ant-btn-danger-disabled:hover, .ant-btn-danger.disabled:hover, .ant-btn-danger[disabled]:hover, .ant-btn-danger-disabled:focus, .ant-btn-danger.disabled:focus, .ant-btn-danger[disabled]:focus, .ant-btn-danger-disabled:active, .ant-btn-danger.disabled:active, .ant-btn-danger[disabled]:active, .ant-btn-danger-disabled.active, .ant-btn-danger.disabled.active, .ant-btn-danger[disabled].active':
		{
			color: 'rgba(0, 0, 0, 0.25)!important',
			background: '#f5f5f5!important',
			borderColor: '#d9d9d9!important',
			textShadow: 'none!important',
			boxShadow: 'none!important',
		},
	'.ant-btn-danger': {
		color: theme.bgColor.main + '!important',
		background: '#ff4d4f!important',
		'&:hover': {
			color: theme.bgColor.main + ' !important',
			background: '#ff7875!important',
		},
		'&:active': {
			color: theme.bgColor.main + ' !important',
			background: '#ff4d4f!important',
		},
	},
	'.ant-btn-dangerous.ant-btn-primary': {
		color: theme.bgColor.main + ' !important',
		background: '#ff4d4f!important',
		'&:hover': {
			color: theme.bgColor.main + ' !important',
			background: '#ff7875!important',
		},
		'&:active': {
			color: theme.bgColor.main + ' !important',
			background: '#ff4d4f!important',
		},
	},
	'.ant-btn-danger.ant-btn-background-ghost': {
		color: '#ff4d4f!important',
		backgroundColor: 'transparent!important',
		borderColor: '#ff4d4f!important',
		'&:hover': {
			color: '#ff7875!important',
			borderColor: '#ff7875!important',
		},
	},

	'.ant-btn-dangerous': {
		color: '#ff4d4f !important',
		borderColor: '#ff4d4f !important',
		'&:hover': {
			color: '#ff7875!important',
		},
	},
	'.ant-table-row-expand-icon': {
		backgroundColor: theme.bgColor.main,
		color: theme.text.primary,
		inlineSize: '19px',
		blockSize: '19px',
		'&:before': {
			insetBlockStart: '8px',
			insetInlineEnd: '2px',
			insetInlineStart: '2px',
		},
		'&:after': {
			insetBlockStart: '2px',
			insetBlockEnd: '2px',
			insetInlineStart: '8px',
		},
	},
	'.ant-table-row-expand-icon-spaced': {
		backgroundColor: 'transparent',
	},
	'.ant-select-selection__clear': {
		backgroundColor: theme.bgColor.main,
	},
	'div.ant-modal-content': {
		backgroundColor: theme.bgColor.main,
		padding: 0 + '!important',
	},
	'.text-primary, .ant-modal-title': {
		color: theme.text.primary,
	},
	'.text-secondary': {
		color: theme.text.secondary,
	},
	'.ant-typography': {
		color: theme.text.primary,
	},
	'.ant-table-cell': {
		overflow: 'auto',
		'& .ant-typography': {
			margin: 0,
			color: 'inherit',
		},
		'&.ant-table-cell-fix-right': {
			background: `${theme.bgColor.main} !important`,
		},
	},
	'@media screen and (-webkit-min-device-pixel-ratio:0)': {
		'.ant-table-cell': {
			overflow: 'visible',
		},
	},
	'.ant-popover-inner': {
		backgroundColor: theme.bgColor.popover,
	},
	'.ant-popover-arrow': {
		borderColor: theme.bgColor.popover + '!important',
	},
	'.ant-checkbox-wrapper': {
		color: theme.text.primary,
		backgroundColor: 'inherit',
		display: 'flex',
		alignItems: 'center',
		'&.ant-checkbox-wrapper-disabled': {
			span: {
				color: theme.text.disabled,
			},
			'.ant-checkbox-inner:after': {
				borderColor: theme.text.disabled,
			},
		},
	},
	'.ant-empty': {
		margin: 'auto',
	},
	'.ant-empty-normal': {
		color: theme.text.secondary,
	},
	'.ant-table-filter-dropdown': {
		backgroundColor: theme.bgColor.popover,
		'.ant-radio-group': {
			paddingBlockEnd: 20,
			maxBlockSize: '50vh',
			overflowY: 'auto',
		},
	},
	'.ant-radio-wrapper': {
		color: theme.text.primary,
	},
	'.ant-radio-button, .ant-radio-button-wrapper': {
		color: theme.text.primary,
		borderRadius: 'inherit',
		borderColor: theme.border.secondary,
		'&:not(:first-child)::before': {
			backgroundColor: theme.border.secondary,
		},
	},
	'.ant-radio-button-wrapper-checked::before': {
		backgroundColor: '#1890ff !important',
	},
	'.ant-radio-button-wrapper:first-child': {
		borderColor: theme.border.secondary,
	},
	'tbody > tr.ant-table-placeholder:hover > td': {
		backgroundColor: theme.bgColor.table.hover,
	},
	'.ant-table-filter-trigger': {
		'&>div': {
			padding: 2,
			borderRadius: 4,
		},
	},
	'.ant-table-filter-trigger-container-open': {
		backgroundColor: theme.bgColor.table.hover,
	},
	'.ant-table-filter-trigger-container:hover': {
		backgroundColor: theme.bgColor.table.hover,
	},
	'.ant-table-filter-trigger:hover': {
		backgroundColor: theme.bgColor.table.hover,
	},
	'.ant-tooltip-open:hover > ant-table-filter-trigger-container': {
		backgroundColor: theme.bgColor.table.hover,
	},
	'.ant-table-column-sorters-with-tooltip:hover .ant-table-filter-trigger-container': {
		backgroundColor: theme.bgColor.table.hover,
	},
	'.ant-table-thead th.ant-table-column-has-sorters:hover .ant-table-filter-trigger-container': {
		backgroundColor: theme.bgColor.table.hover,
	},
	'.ant-table-thead th.ant-table-column-has-sorters:hover': {
		backgroundColor: theme.bgColor.table.hover,
	},
	'.ant-table-thead th.ant-table-column-has-sorters:hover .ant-table-filter-trigger-container:hover': {
		backgroundColor: theme.bgColor.table.hover,
	},
	'.ant-input-affix-wrapper': {
		backgroundColor: theme.bgColor.main,
	},
	'.ant-select-dropdown': {
		backgroundColor: theme.bgColor.main,
		boxShadow: theme.shadow,
	},
	'.ant-card-bordered': {
		backgroundColor: theme.bgColor.main,
	},
	'.ant-select-tree-title': {
		color: theme.text.primary,
	},
	'.ant-select-tree': {
		backgroundColor: theme.bgColor.header,
	},
	'.ant-breadcrumb-separator': {
		color: theme.text.primary,
	},
	'.ant-menu-submenu': {
		color: theme.text.primary,
	},
	'.ant-menu-submenu-selected': {
		color: '#1890ff',
	},
	'.ant-divider': {
		backgroundColor: theme.border.primary,
	},
	'.ant-table-cell .ant-btn-link': {
		padding: 0,
	},
	'.ant-table thead > tr > th, .ant-table-small .ant-table-thead > tr > th': {
		borderBottomColor: theme.border.primary,
		backgroundColor: theme.bgColor.table.header,
	},
	'th.ant-table-cell.ant-table-cell-fix-right': {
		backgroundColor: theme.bgColor.table.header + '!important',
	},
	'.ant-table-tbody > tr > td': {
		borderBottomColor: theme.border.primary,
	},
	'.ant-select-dropdown-menu-item': {
		color: theme.text.primary,
	},
	'.ant-dropdown': {
		zIndex: 1000,
	},
	'.ant-dropdown-menu': {
		padding: '8px 0',
		borderRadius: 8,
		backgroundColor: theme.bgColor.main,
		'.ant-dropdown-menu-item': {
			color: theme.text.primary,
			'&:hover': {
				backgroundColor: theme.bgColor.activeSelect,
			},
		},
	},
	'.ant-select-dropdown-menu-item-active:not(.ant-select-dropdown-menu-item-disabled)': {
		backgroundColor: theme.bgColor.field.option.hover + '!important',
	},
	'.ant-select-dropdown-menu-item-selected': {
		backgroundColor: theme.bgColor.field.option.active + '!important',
	},
	'.ant-select-selector': {
		borderRadius: '4px!important',
		borderColor: theme.border.secondary,
		backgroundColor: theme.bgColor.main + '!important',
		color: theme.text.primary,
	},
	'.ant-tabs-nav': {
		marginBlockEnd: 16,
	},
	'.ant-tabs-tabpane-active > .ant-tabs-card > .ant-tabs-nav >.ant-tabs-nav-wrap > .ant-tabs-nav-list > .ant-tabs-tab':
		{
			backgroundColor: theme.bgColor.sort,
			borderColor: theme.border.secondary,
		},
	'.ant-picker.ant-picker-disabled': {
		backgroundColor: theme.bgColor.disabled,
		color: theme.text.disabled,
	},
	'.ant-picker-input > input': {
		color: theme.text.primary,
		'&[disabled]': {
			color: theme.text.disabled,
			'& > .ant-picker-suffix': {
				color: theme.text.disabled,
			},
		},
	},
	'.ant-select-selection__choice': {
		color: theme.text.primary + '!important',
		backgroundColor: theme.bgColor.main + '!important',
		'& i': {
			color: theme.text.primary + '!important',
		},
	},
	'.ant-select-multiple .ant-select-selection-item': {
		backgroundColor: theme.bgColor.secondary,
		color: theme.text.primary,
	},
	'.ant-select-arrow': {
		color: theme.text.secondary,
	},
	'.ant-select-item-option-content': {
		color: theme.text.primary,
	},
	'.ant-select-item-option-active:not(.ant-select-item-option-disabled)': {
		color: theme.text.primary + '!important',
		backgroundColor: theme.bgColor.activeSelect,
	},
	'.ant-select-item:not(.ant-select-item-option-disabled)': {
		color: theme.text.primary,
	},
	'.ant-select-item-option-selected:not(.ant-select-item-option-disabled)': {
		color: theme.text.primary + '!important',
		backgroundColor: theme.bgColor.secondary,
	},
	'.ant-select-item-option-disabled': {
		color: theme.text.disabled + ' !important',
		'&>.ant-select-item-option-content': {
			color: theme.text.disabled + ' !important',
		},
	},
	'.ant-select-item-option-active > .ant-select-item-option-selected': {
		color: theme.text.primary + '!important',
		backgroundColor: theme.bgColor.activeSelect,
	},
	'.ant-select-item-option-selected > .ant-select-item-option-selected': {
		color: theme.text.primary + '!important',
		backgroundColor: theme.bgColor.main,
	},
	'.ant-upload, .ant-upload-list': {
		color: theme.text.primary + '!important',
	},
	'.ant-tag': {
		fontWeight: 'normal',
		borderRadius: 4,
		color: theme.text.statusNeutral,
		borderColor: theme.border.statusNeutral,
		backgroundColor: theme.bgColor.statusNeutral,
	},
	'.ant-tag-has-color': {
		color: '#fff',
		borderColor: 'transparent',
	},
	'.slick-prev': {
		insetInlineStart: -20,
	},
	'.slick-next': {
		insetInlineEnd: -20,
	},
	'.slick-prev::before, .slick-next::before': {
		color: theme.text.secondary,
	},
	'.ant-table-row-indent + .ant-table-row-expand-icon': {
		marginBlockStart: 4,
	},
	'.ant-badge-count': {
		zIndex: 1,
		backgroundColor: theme.bgColor.badge,
	},
	'.ant-table-cell-with-append': {
		'&>div': {
			display: 'flex',
		},
	},
	'.ant-alert': {
		'&.ant-alert-info': {
			backgroundColor: theme.bgColor.statusBlue,
			'.ant-alert-message': {
				color: theme.text.primary,
			},
		},
		'& .ant-alert-icon': {
			position: 'static!important',
		},
	},
	'.ant-picker': {
		transition: 'all .3s',
		backgroundColor: theme.bgColor.main,
		color: theme.text.primary,
		'&-input': {
			color: theme.text.primary,
		},
		'&-suffix': {
			color: theme.text.secondary,
		},
		'&.ant-picker-disabled .ant-picker-suffix': {
			color: theme.text.disabled,
		},
	},
	'.ant-picker-panel-container': {
		backgroundColor: theme.bgColor.main,
		'.ant-picker-header > button': {
			color: theme.bgColor.switch,
		},
		'.ant-picker-header-view, th, .ant-picker-cell-in-view:not(.ant-picker-cell-disabled, :hover), .ant-picker-time-panel-cell:not(.ant-picker-time-panel-cell-selected, :hover)>div':
			{
				color: theme.text.primary,
			},
		'td:not(.ant-picker-cell-in-view)': {
			color: theme.text.disabled,
		},
		'.ant-picker-cell-disabled': {
			color: theme.text.disabled,
			'&:before': {
				backgroundColor: theme.bgColor.disabled,
			},
		},
	},
	'.ant-tag-green': {
		color: theme.text.statusGreen,
		backgroundColor: theme.bgColor.statusGreen,
		borderColor: theme.border.statusGreen,
	},
	'.ant-tag-volcano': {
		color: theme.text.statusVolcano,
		backgroundColor: theme.bgColor.statusVolcano,
		borderColor: theme.border.statusVolcano,
	},
	'.ant-tag-blue': {
		color: theme.text.statusBlue,
		backgroundColor: theme.bgColor.statusBlue,
		borderColor: theme.border.statusBlue,
	},
	'.ant-tag-purple': {
		color: theme.text.statusPurple,
		backgroundColor: theme.bgColor.statusPurple,
		borderColor: theme.border.statusPurple,
	},
	'.ant-tag-red': {
		color: theme.text.statusRed,
		backgroundColor: theme.bgColor.statusRed,
		borderColor: theme.border.statusRed,
	},
	'.ant-tag-gold': {
		color: theme.text.statusGold,
		backgroundColor: theme.bgColor.statusGold,
		borderColor: theme.border.statusGold,
	},
	'.ant-tag-magneta': {
		color: theme.text.statusMagneta,
		backgroundColor: theme.bgColor.statusMagneta,
		borderColor: theme.border.statusMagneta,
	},
	'.ant-tag-lime': {
		color: theme.text.statusLime,
		backgroundColor: theme.bgColor.statusLime,
		borderColor: theme.border.statusLime,
	},
	'.ant-tag-cyan': {
		color: theme.text.statusCyan,
		backgroundColor: theme.bgColor.statusCyan,
		borderColor: theme.border.statusCyan,
	},
	'.ant-tag-geekblue': {
		color: theme.text.statusGeekblue,
		backgroundColor: theme.bgColor.statusGeekblue,
		borderColor: theme.border.statusGeekblue,
	},
	'.ant-spin-spinning, .ant-spin-container, .ant-spin-nested-loading, .ant-spin-blur': {
		backgroundColor: theme.bgColor.loader,
	},
	'.ant-tabs-top > .ant-tabs-nav::before': {
		borderBottom: `1px solid ${theme.border.primary}`,
	},
	'.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)': {
		color: '#1890ff',
	},
	'.ant-radio-button-wrapper-disabled': {
		color: theme.text.disabled,
	},
	'.ant-tooltip-inner': {
		color: theme.bgColor.main,
		backgroundColor: theme.text.primary,
		opacity: '0.9',
	},
	'.data-test-modal-dangerous-submit .data-test-retry-order-submit, .data-test-cancel-order-submit, .data-test-signal-order-submit':
		{
			background: '#F5222D',
			borderColor: '#F5222D',
			'&:hover': {
				background: '#F5222D',
				borderColor: '#F5222D',
			},
			'&:focus': {
				background: '#FF4D4F',
				borderColor: '#FF4D4F',
			},
			'&:active': {
				background: '#CF1322',
				borderColor: '#CF1322',
			},
		},
	'.ant-table.ant-table-small .ant-table-expanded-row-fixed': {
		margin: '-16px -16px',
	},
	'.ant-switch:not(.ant-switch-checked)': {
		backgroundColor: theme.bgColor.switch,
	},
	'.ant-notification-notice-message': {
		color: theme.text.primary,
		backgroundColor: theme.bgColor.notification,
	},
	'.ant-notification-notice-description': {
		color: theme.text.primary,
		backgroundColor: theme.bgColor.notification,
	},
	'.ant-notification-notice': {
		color: theme.text.primary,
		backgroundColor: theme.bgColor.notification,
	},
	'.ant-tabs-nav-more': {
		color: theme.text.primary,
	},
	'.ant-tabs-dropdown-menu': {
		backgroundColor: theme.bgColor.secondary,
		'&>li': {
			color: theme.text.primary,
		},
	},
	'.ant-tabs-dropdown-menu-item-active': {
		backgroundColor: theme.bgColor.activeSelect + ' !important',
	},
	'.ant-tabs-card > .ant-tabs-nav .ant-tabs-tab ': {
		borderBottom: 'none !important',
	},
	'.ant-tabs-tab-active': {
		backgroundColor: theme.bgColor.main + '!important',
	},
	'.ant-upload-drag': {
		backgroundColor: theme.bgColor.main + ' !important',
	},
	'.tag-ellipsis': {
		whiteSpace: 'nowrap',
		display: 'inline-block',
		maxInlineSize: '150px',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
	},
	'.tag-view-only': {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		backgroundColor: theme.bgColor.field.option.active,
		padding: '1px 7px',
		border: `1px solid ${theme.border.secondary}`,
		borderRadius: 4,
	},
	'.tag-view-only .ant-tag': {
		margin: 0,
		border: 0,
	},
	'.ant-collapse > .ant-collapse-item > .ant-collapse-header': {
		color: theme.text.secondary,
		fontWeight: 500,
		'&:hover': {
			fontWeight: 500,
			color: theme.text.btnLink,
		},
	},
	'.ant-table': {
		backgroundColor: theme.bgColor.header,
	},
	'.ant-tabs': {
		color: theme.text.primary,
	},
	'.ant-collapse-content': {
		backgroundColor: theme.bgColor.header,
	},
	'.ant-collapse > .ant-collapse-item': {
		border: 'none',
	},
	'.ant-tree': {
		color: theme.text.primary,
	},
	'.ant-tree-treenode:focus': {
		backgroundColor: theme.bgColor.secondary,
	},
	'.ant-table-title': {
		padding: '12px 8px',
	},
	'.ant-steps': {
		'.ant-steps-item-title': {
			color: `${theme.text.primary} !important`,
		},
	},
	'.lavka-ui-kit-header__extra-content_placement_right': {
		marginInlineStart: '12px',
	},
	'@media print': {
		'@page': {
			margin: '10mm 15mm 15mm 10mm',
		},
		'.ant-table table': {
			breakInside: 'auto',
			borderWidth: '0 !important',
			borderRadius: '0 !important',
		},
		'.ant-table tr': {
			breakInside: 'avoid',
		},
		'.ant-table .ant-table-cell, .ant-table thead .ant-table-cell': {
			padding: '3px !important',
			border: 'solid #000 !important',
			borderWidth: '0 1px 1px 1px !important',
			borderRadius: '0px !important',
			wordWrap: 'break-word',
			whiteSpace: 'normal',
		},
		'.ant-table thead .ant-table-cell': {
			borderBlockStartWidth: '1px !important',
		},
		'.ant-table .ant-table-cell + .ant-table-cell': {
			borderInlineStartWidth: '0 !important',
		},
		'.ant-table tfoot.ant-table-summary': {
			display: 'table-row-group',
		},
	},
	// TODO: LAVKADEV-13822 Временный хак, пока не пофиксим ui-kit
	'.lavka-ui-kit-notifications': {
		marginInlineEnd: '32px',
		inlineSize: '336px',
		maxInlineSize: '336px !important',
		insetBlockStart: 'auto',
	},
	'.lavka-ui-kit-notification__message': {
		whiteSpace: 'initial',
	},
});

const GlobalStyles: FC = () => {
	const { theme } = useStyles();
	const globalStyles = useMemo(() => styles(theme), [theme]);

	return <TssGlobalStyles styles={globalStyles as unknown as CSSInterpolation} />;
};

export default GlobalStyles;
