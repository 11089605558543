import { useCallback, useState } from 'react';

export function usePagination<T>(data: T[]) {
	const [paginationValue, setPaginationValue] = useState(0);

	const tempPaginationValue = data.length === 0 ? 0 : Math.min(paginationValue, data.length - 1);

	const incrementPaginationValue = useCallback(() => {
		if (tempPaginationValue + 1 < data.length) {
			setPaginationValue((prev) => prev + 1);
		}
	}, [data, tempPaginationValue]);

	const decrementPaginationValue = useCallback(() => {
		if (tempPaginationValue > 0) {
			setPaginationValue((prev) => prev - 1);
		}
	}, [data, tempPaginationValue]);

	return {
		paginationData: data[tempPaginationValue],
		incrementPaginationValue,
		decrementPaginationValue,
	};
}
