import { Button } from 'antd';
import dayjs from 'dayjs';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { ReactComponent as EditIcon } from '~images/icons/edit-icon.svg';
import AssignMentor from '~shared/components/MenteeMentorField/AssignMentor';
import { MILESTONES } from '~shared/components/MenteeMentorField/const';
import { getPotentialMentors } from '~shared/components/MenteeMentorField/utils';
import userName from '~shared/utils/userName';
import type { User } from '~types/user';
import { useCheckPermit } from '~zustand/userData';

import { useStyles } from './styles';

const Mentee = ({
	user,
	mentorData,
	usersData,
	updateRerenderKey,
}: {
	user: Partial<User.User>;
	mentorData?: Partial<User.User>;
	usersData: User.User[];
	updateRerenderKey: () => void;
}) => {
	const { classes, cx } = useStyles();
	const [t] = useTranslation();

	const [assignVisible, setAssignVisible] = useState(false);
	const usersList = getPotentialMentors(usersData);

	const canAssignMentor =
		!mentorData &&
		dayjs(user.created ?? '')
			.add(MILESTONES.canAssign, 'days')
			.isAfter(dayjs());

	const canReAssignMentor =
		!!mentorData &&
		dayjs(user.vars?.mentoring_since ?? '')
			.add(MILESTONES.canReAssign, 'days')
			.isAfter(dayjs());

	const onlyExMentorButStillNoob =
		dayjs(user.vars?.mentoring_until).add(1, 'day').isBefore(dayjs()) &&
		dayjs().isBefore(dayjs(user.created).add(MILESTONES.canBeMentorAfter, 'day'));

	const assignPermit = useCheckPermit('users_save');

	return (
		<>
			<AssignMentor
				visible={assignVisible}
				updateRerenderKey={updateRerenderKey}
				setModalVisible={setAssignVisible}
				usersList={usersList}
				userId={user.user_id}
				mentorId={user.mentor_id}
				editUser={user}
			/>
			<div className={classes.container}>
				<div className={classes.headerContainer}>
					{<div className={classes.header}> {onlyExMentorButStillNoob ? t('Бывший наставник') : t('Наставник')}</div>}
					{(canAssignMentor || canReAssignMentor) && (
						<Button
							disabled={!assignPermit}
							className={classes.addButton}
							type="primary"
							ghost
							onClick={() => setAssignVisible(true)}
						>
							<EditIcon />
							{!mentorData ? t('Назначить') : t('Изменить')}
						</Button>
					)}
				</div>
				<div>
					{!!mentorData ? (
						<div className={cx(classes.menteeContainer, classes.mentorContainer)}>
							<Link to={`/stores/${user.store_id}/users/${mentorData.user_id}`}>{userName(mentorData)}</Link>
							<div className={classes.menteeTime}>
								{!onlyExMentorButStillNoob &&
									t('Наставник до: {{date}}', { date: dayjs(user.vars?.mentoring_until).format('DD.MM.YYYY') })}
								{onlyExMentorButStillNoob &&
									t('Наставник c {{dateFrom}} по {{dateTo}}', {
										dateTo: dayjs(user.vars?.mentoring_until).format('DD.MM.YYYY'),
										dateFrom: dayjs(user.vars?.mentoring_since).format('DD.MM.YYYY'),
									})}
							</div>
						</div>
					) : (
						<div className={cx(classes.menteeContainer, classes.mentorContainer, classes.noMentor)}>
							{t('Пока никого нет')}
						</div>
					)}
				</div>
			</div>
		</>
	);
};

export default Mentee;
