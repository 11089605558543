import { Col, Row, Tooltip } from 'antd';
import type { Dayjs } from 'dayjs';
import { useTranslation } from 'react-i18next';

import type { CalendarParams } from '~constants/dataPageFields/types';
import { ReactComponent as TooltipIcon } from '~images/question-circle-solid.svg';
import TimePicker from '~shared/components/DayJsComponents/TimePicker';
import { formatTimeToDayjs, formatTimeToString } from '~shared/utils/momentFormatters';

import { useStyles } from '../styles';
import { useStyles as useTextStyles } from '../TextField/styles';
import type { FieldProps } from '../types';
import { EmptyValue, getColSpan } from '../utils';
import { COLSPAN } from './constants';

interface TimeFieldProps extends FieldProps {
	addMode?: boolean;
	colspan?: Record<
		string,
		{
			span: number;
		}
	>;
	format?: string;
	params?: CalendarParams;
	pickerParams?: Record<string, any>;
	removeLabel?: boolean;
	hideLabel?: boolean;
	fieldRequired?: boolean;
	disabledDate?: (currentDate: Dayjs) => boolean;
	isMinutesReadOnly?: boolean;
	tooltip?: string;
	allowClear?: boolean;
	wordHour?: boolean;
	dataTest?: string;
	rowClassName?: string;
	viewModeTimeFormatter?: (value: string) => string;
	width?: string;
	type?: 'range';
	placeholder?: string | [string, string];
}

const TimeField = ({
	id,
	label = '',
	input,
	disabled,
	meta: { error },
	colspan = COLSPAN,
	editMode,
	format = 'HH:mm:ss',
	removeLabel = false,
	hideLabel = false,
	fieldRequired,
	disabledDate,
	tooltip,
	allowClear,
	pickerParams,
	wordHour = false,
	isMinutesReadOnly = false,
	dataTest,
	rowClassName,
	viewModeTimeFormatter,
	width,
	type,
	placeholder,
}: TimeFieldProps) => {
	const [t] = useTranslation();
	const { cx, classes } = useStyles();
	const { classes: textClasses } = useTextStyles({ error });
	const isTimeRange = type === 'range';

	const getValueOnViewMode = () => {
		if (input.value && wordHour) {
			return t('{{hour}} часов', {
				hour: formatTimeToDayjs(input.value, format).format('HH'),
			});
		}

		if (input.value) {
			if (isTimeRange) {
				if (!input.value[0] && !input.value[1]) {
					return t('Не заполнено');
				}

				return t('С {{from}} до {{to}}', {
					from: viewModeTimeFormatter
						? viewModeTimeFormatter(input.value[0])
						: formatTimeToString(input.value[0], format),
					to: viewModeTimeFormatter
						? viewModeTimeFormatter(input.value[1])
						: formatTimeToString(input.value[1], format),
				});
			}

			return viewModeTimeFormatter ? viewModeTimeFormatter(input.value) : formatTimeToString(input.value, format);
		}

		return <EmptyValue />;
	};

	const getInputValue = (): Dayjs | [Dayjs, Dayjs] | undefined => {
		if (isTimeRange) {
			if (Array.isArray(input.value) && input.value.every(Boolean)) {
				return [formatTimeToDayjs(input.value[0], format), formatTimeToDayjs(input.value[1], format)];
			}

			return undefined;
		}

		return input.value ? formatTimeToDayjs(input.value, format) : undefined;
	};

	return (
		<Row
			align="top"
			gutter={[0, { xs: 0, sm: editMode ? 10 : 20 }]}
			className={rowClassName}
			style={{ inlineSize: width }}
		>
			{!removeLabel && (
				<Col {...getColSpan(colspan)} className={classes.labelContainer}>
					{!hideLabel && (
						<>
							<div className={classes.tooltipWrapper}>
								<label
									className={cx(classes.label, {
										[classes.labelRequired]: editMode && fieldRequired,
									})}
									htmlFor={id}
								>
									{label}
								</label>
								{tooltip && (
									<Tooltip title={tooltip}>
										<TooltipIcon width="12" height="12" className={classes.labelTooltip} />
									</Tooltip>
								)}
							</div>

							<div className={classes.dotSeparator} />
						</>
					)}
				</Col>
			)}
			<Col {...getColSpan(colspan)} className={classes.readOnlyFieldContainer}>
				{editMode ? (
					<>
						<div className={cx(classes.inputContainer, 'wms-input-container')}>
							{
								// @ts-expect-error проблема с типизацией из-за того, что может быть [Dayjs, Dayjs]
								<TimePicker
									placeholder={placeholder ?? t('Выбрать время')}
									allowClear={allowClear}
									value={getInputValue()}
									format={format}
									data-test={dataTest ?? `data form ${label}`}
									onChange={(time: any) => {
										if (isTimeRange && time) {
											return input.onChange([
												(isMinutesReadOnly
													? time?.[0]?.minute(0).second(0).format(format)
													: time?.[0]?.format(format)) || null,
												(isMinutesReadOnly
													? time?.[1]?.minute(0).second(0).format(format)
													: time?.[1]?.format(format)) || null,
											]);
										}

										return input.onChange(
											(isMinutesReadOnly ? time?.minute(0).second(0).format(format) : time?.format(format)) || null
										);
									}}
									isTimeRange={isTimeRange}
									disabledDate={disabledDate}
									disabled={disabled}
									className={cx(textClasses.input, {
										[textClasses.inputError]: !!error,
									})}
									popupClassName={`data-test-timepicker-${label.replace(/[,().]/g, '').replace(/\s/g, '-')}`}
									{...pickerParams}
								/>
							}
						</div>
						{error && (
							<span
								className={cx({ [classes.inputTextError]: error }, classes.inputErrorContainer)}
								data-test={`data form ${label} error`}
							>
								{error}
							</span>
						)}
					</>
				) : (
					<p className={classes.valueOnViewMode} data-test={dataTest ?? `data form ${label}`}>
						{getValueOnViewMode()}
					</p>
				)}
			</Col>
		</Row>
	);
};

export default TimeField;
