import { Alert } from 'antd';
import dayjs from 'dayjs';
import type { ReactElement } from 'react';
import { useEffect, useState } from 'react';
import type { FieldValues } from 'react-hook-form';
import { useForm, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { ReactComponent as StarIcon } from '~images/icons/star-icon.svg';
import UnifiedMultiSelect from '~shared/components/Fields/MultipleEntitySelect/UnifiedMultiSelect';
import { Field, Form } from '~shared/components/Forms';
import { MILESTONES } from '~shared/components/MenteeMentorField/const';
import { assign } from '~shared/components/MenteeMentorField/utils';
import ModalComponent from '~shared/components/ModalComponent';
import userName from '~shared/utils/userName';
import type { User } from '~types/user';

import { useStyles } from './styles';

const AssignMentor = ({
	userId,
	usersList,
	visible,
	setModalVisible,
	updateRerenderKey,
	mentorId,
	editUser,
}: {
	userId?: string;
	usersList?: Partial<User.User>[];
	setModalVisible: (value: boolean) => void;
	visible: boolean;
	updateRerenderKey: () => void;
	mentorId?: string;
	editUser: Partial<User.User>;
}) => {
	const { classes } = useStyles();
	const [t] = useTranslation();
	const form = useForm();
	const { handleSubmit, reset, control } = form;
	const selectedValue = useWatch({ control, name: 'user_id' });
	const [dictionary, setDictionary] = useState<Record<string, ReactElement>>({});
	const [cancelModalVisible, setCancelModalVisible] = useState(false);
	const [searchData, setSearchData] = useState({ title: undefined });
	const searchUser = (values: typeof searchData) => {
		setSearchData({
			...values,
		});
	};

	useEffect(() => {
		setDictionary(
			usersList?.reduce((map: Record<string, any>, executor: Partial<User.User>) => {
				if (userName(executor).includes(searchData.title ?? '') && executor.user_id !== mentorId) {
					map[executor.user_id!] = userName(executor, { showDisplayName: true });
				}
				return map;
			}, {}) ?? {}
		);
	}, [selectedValue, searchData, visible]);

	const onSubmit = async (value: FieldValues) => {
		await assign({
			notificationText: { success: t('Наставник назначен'), error: t('Не удалось назначить ментора') },
			updateRerenderKey,
			setModalVisible,
			reset,
			userId: userId ?? '',
			mentorId: value.user_id,
		});
	};

	const startDate = editUser.vars?.mentoring_since ? editUser?.vars.mentoring_since : editUser?.created;

	return (
		<>
			<ModalComponent
				open={cancelModalVisible && !mentorId}
				onCancel={() => {
					setCancelModalVisible(false);
					setModalVisible(true);
				}}
				onOk={() => {
					setCancelModalVisible(false);
					setModalVisible(false);
					reset();
				}}
				title={t('Отменить выбор наставника?')}
			>
				{t('' + 'Вы сможете выбрать наставника на странице сотрудника до {{date}} включительно', {
					date: dayjs(startDate)
						.add(MILESTONES.canAssign - 1, 'day')
						.format('DD.MM.YYYY'),
				})}
			</ModalComponent>
			<ModalComponent
				title={t('Выберите наставника')}
				open={visible}
				onOk={handleSubmit(onSubmit)}
				onCancel={() => {
					setModalVisible(false);
					setCancelModalVisible(true);
				}}
				okText={t('Применить')}
				destroyOnClose={true}
				width={600}
				okButtonProps={{
					disabled: !selectedValue,
				}}
			>
				{!selectedValue && !mentorId && (
					<Alert
						className={classes.alert}
						icon={<StarIcon height={16} width={16} className={classes.alertIcon} />}
						message={
							<>
								<div className={classes.alertHeader}>{t('Этот кладовщик - новичок')}</div>
								<div className={classes.alertText}>{t('Нужна помощь наставника с адаптацией')}</div>
							</>
						}
						type="success"
						showIcon
					/>
				)}
				<Form key="mentee_form" hookForm={form}>
					<Field
						key="user_id"
						name="user_id"
						dataTest="user_id"
						label={t('Наставник')}
						editMode
						allowClear
						colspan={{
							xl: { span: 12 },
							lg: { span: 12 },
							sm: { span: 12 },
							xs: { span: 12 },
						}}
						component={UnifiedMultiSelect}
						setSearchData={searchUser}
						showSearch={true}
						placeholder={t('Выберите сотрудника')}
						options={Object.keys(dictionary ?? {})}
						dictionary={dictionary}
					/>
				</Form>
			</ModalComponent>
		</>
	);
};

export default AssignMentor;
