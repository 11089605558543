import type { Dispatch, JSX, SetStateAction } from 'react';
import { useContext, useMemo, useState } from 'react';

import Chart from '~shared/components/Charts/Chart';

import { HealthContext } from '../../context';
import type { HealthLevelMetrics, HealthMetric, PeriodState } from '../../healthTypes';
import type { ThresholdDataEntry } from '../../hooks/useGetThresholds';
import { useMetricsStore } from '../../hooks/useMetricsStore';
import type { TickValue } from '../ChartsComparisonModal';
import ChartHeader from './ChartHeader';
import { defaultConfig, getGraphFormatter } from './constants';
import { useStyles } from './styles';
import { getAveragedData, getMetricGraphData } from './utils';

export type EnrichedGraphsPoint = HealthLevelMetrics & {
	comparisonType?: PeriodState['comparisonType'];
	label?: string;
};

type Props = {
	data?: EnrichedGraphsPoint[];
	retroData?: EnrichedGraphsPoint[];
	id: string;
	setModalOpen?: Dispatch<SetStateAction<boolean>>;
	chartLoading: boolean;
	comparisonType?: PeriodState['comparisonType'];
	dataTest?: string;
	isAllRetroUsed?: boolean;
	thresholds?: ThresholdDataEntry;
	syncId?: string;
	customTooltip?: JSX.Element;
	tickValue?: TickValue;
	isDataByHour?: boolean;
};

const ChartBlock = ({
	data,
	retroData,
	id,
	setModalOpen,
	chartLoading,
	comparisonType,
	dataTest,
	isAllRetroUsed,
	thresholds,
	syncId,
	customTooltip,
	tickValue,
	isDataByHour,
}: Props) => {
	const { classes } = useStyles();
	const [showTooltip, toggleTooltip] = useState(false);

	const metric = useMetricsStore((state) => state.getMetric(id).metric);
	const isLastMetric = useMetricsStore((state) => state.metrics.length === 1);
	const setMetric = useMetricsStore((state) => state.setMetric);
	const removeMetric = useMetricsStore((state) => state.removeMetric);

	const { periodState, tzParams } = useContext(HealthContext);

	const comparison = comparisonType ?? periodState.comparisonType;

	const config = {
		...defaultConfig,
		tickFormatter: getGraphFormatter(metric),
		valueFormatter: getGraphFormatter(metric),
		customTooltip,
		yAxisWidth: metric === 'full_cte' ? 95 : undefined,
	};

	const currentData = useMemo(() => {
		if (!data) return [];
		return getMetricGraphData({
			metric,
			data,
			retroData,
			comparisonType: comparison,
			thresholds,
			isAllRetroUsed,
			timezone: tzParams.timezone,
		});
	}, [metric, data, retroData, thresholds, tzParams.timezone]);

	const averagedData = useMemo(
		() => getAveragedData(currentData, isDataByHour, tickValue),
		[currentData, tickValue, isDataByHour]
	);

	return (
		<div data-test={dataTest ?? 'health chart block'} className={classes.chartBlock}>
			<ChartHeader
				metric={metric}
				setMetric={(metric: HealthMetric) => setMetric(metric, id)}
				setModalOpen={setModalOpen}
				removeMetric={() => removeMetric(id)}
				isLastMetric={isLastMetric}
				timezoneMessage={tzParams.message}
			/>
			<div
				className={classes.chartContainer}
				onMouseEnter={() => {
					toggleTooltip(true);
				}}
				onMouseLeave={() => {
					toggleTooltip(false);
				}}
			>
				<Chart
					loading={chartLoading}
					type="area"
					data={averagedData}
					unit=""
					alterKey="retroValue"
					config={config}
					syncId={syncId}
					showTooltip={showTooltip}
				/>
			</div>
		</div>
	);
};

export default ChartBlock;
