import { useEffect, useState } from 'react';
import type { FieldValues } from 'react-hook-form';
import { useForm, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import UnifiedMultiSelect from '~shared/components/Fields/MultipleEntitySelect/UnifiedMultiSelect';
import { Field, Form } from '~shared/components/Forms';
import { assign } from '~shared/components/MenteeMentorField/utils';
import ModalComponent from '~shared/components/ModalComponent';
import userName from '~shared/utils/userName';
import type { User } from '~types/user';
import { useUser } from '~zustand/userData';

const AssignMentee = ({
	userId,
	usersData,
	visible,
	setModalVisible,
	updateRerenderKey,
}: {
	userId?: string;
	usersData?: Partial<User.User>[];
	setModalVisible: (value: boolean) => void;
	visible: boolean;
	updateRerenderKey: () => void;
}) => {
	const [t] = useTranslation();
	const form = useForm();
	const { handleSubmit, reset, control } = form;
	const selectedValue = useWatch({ control, name: 'user_id' });
	const [dictionary, setDictionary] = useState<Record<string, string>>({});
	const user = useUser();
	useEffect(() => {
		setDictionary(
			usersData?.reduce((map: Record<string, any>, executor: Partial<User.User>) => {
				map[executor.user_id!] = userName(executor, { showDisplayName: true });
				return map;
			}, {}) ?? {}
		);
		if (user.role === 'store_admin') {
			setDictionary((pre) => {
				pre[user.user_id!] = userName(user, { showDisplayName: true });
				return pre;
			});
		}
	}, [selectedValue]);

	const onSubmit = async (value: FieldValues) => {
		await assign({
			notificationText: { success: t('Подопечный назначен'), error: t('Не удалось назначить подопечного') },
			updateRerenderKey,
			setModalVisible,
			reset,
			userId: value.user_id,
			mentorId: userId ?? '',
		});
	};

	return (
		<ModalComponent
			title={t('Выберите подопечного-новичка')}
			open={visible}
			onOk={handleSubmit(onSubmit)}
			onCancel={() => {
				setModalVisible(false);
				reset();
			}}
			okText={t('Применить')}
			destroyOnClose={true}
			width={600}
			okButtonProps={{
				disabled: !selectedValue,
			}}
		>
			<Form key="mentee_form" hookForm={form}>
				<Field
					key="user_id"
					name="user_id"
					label={t('Подопечный')}
					editMode
					allowClear
					colspan={{
						xl: { span: 12 },
						lg: { span: 12 },
						sm: { span: 12 },
						xs: { span: 12 },
					}}
					component={UnifiedMultiSelect}
					showSearch={true}
					placeholder={t('Выберите сотрудника')}
					options={Object.keys(dictionary ?? {})}
					dictionary={dictionary}
				/>
			</Form>
		</ModalComponent>
	);
};

export default AssignMentee;
