import type { Dispatch, SetStateAction } from 'react';
import { createContext } from 'react';

import { makeDynamicTranslations, tDynamic } from '~shared/utils/makeDynamicTranslations';
import type { Orders } from '~types/orders';
import type { Stores } from '~types/stores';

import { defaultPeriodState, UTC_0_TIMEZONE } from './constants';
import type { PeriodState } from './healthTypes';
import type { ViewData } from './index';

type HealthContextType = {
	periodState: PeriodState;
	loading: boolean;
	userStoreId?: string;
	userStore?: Stores.Store;
	setOrderIdToShow: Dispatch<SetStateAction<Orders.Order['order_id'] | undefined>>;
	levelState: ViewData;
	tzParams: {
		timezone?: string | undefined;
		message?: string;
	};
};

export const HealthContext = createContext<HealthContextType>(
	makeDynamicTranslations({
		periodState: defaultPeriodState,
		loading: false,
		userStoreId: '',
		setOrderIdToShow: () => {},
		levelState: { type: 'cluster' },
		userStore: undefined,
		tzParams: {
			timezone: UTC_0_TIMEZONE,
			message: tDynamic('Указывается в UTC+0'),
		},
	})
);
