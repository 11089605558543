import { Col, Row } from 'antd';
import type { CancelTokenSource } from 'axios';
import type { JSX } from 'react';
import { useRef } from 'react';
import { Link } from 'react-router-dom';

import type { Entity } from '~cache/cache';
import { useCache } from '~cache/useCache';
import { createCustomI18n } from '~shared/components/DataLanguageSelector/i18n';
import DeleteButton from '~shared/components/DeleteButton';
import useLoadData from '~shared/hooks/useLoadData';
import { useCheckExp, useUser } from '~zustand/userData';

import { fetchProductsData } from '../../../../components/ProductsPage/utils';
import type { SelectFieldProps } from '../SelectField';
import SelectWithSearch from '../SelectField/SelectWithSearch';
import { useStyles } from '../styles';
import { EmptyValue, getColSpan } from '../utils';

interface OwnProps {
	entity: 'cluster' | 'store' | 'product' | 'company' | 'courier_service';
	keyId: 'clusters' | 'stores' | 'products' | 'companies' | 'courierServices';
	linkCategory: 'clusters' | 'stores' | 'products' | 'companies' | 'courier_services';
	viewProp?: string;
	searchFunction: (value: string, cancelToken?: CancelTokenSource, options?: Record<string, any>) => any;
	valueString: string;
	paramsComponent?: JSX.Element;
	skip?: boolean;
	inputWrapperClassName?: string;
	renderSelectedItemExtraInfo?: (id: string) => JSX.Element;
}

type Props = OwnProps & SelectFieldProps;

const MultipleEntitySelect = ({
	entity,
	keyId,
	searchFunction,
	valueString,
	paramsComponent,
	linkCategory,
	viewProp = 'title',
	skip,
	inputWrapperClassName,
	renderSelectedItemExtraInfo,
	...props
}: Props) => {
	const { cx, classes } = useStyles();

	const cancelTokenSource = useRef<CancelTokenSource>();
	const isExpSeparateDataLang = useCheckExp('exp_separate_data_lang');
	const user = useUser();
	const customI18n = createCustomI18n({ lng: user?.data_language });

	// @ts-expect-error
	const cache = useCache({
		[keyId]: !skip ? props.input.value : undefined,
	});

	useLoadData(
		() =>
			fetchProductsData(
				{
					ids: props.input.value,
					_fields: ['long_title', 'title'] as keyof Entity<'products'>['_fields'],
				},
				isExpSeparateDataLang
			),
		[customI18n?.options?.lng],
		keyId !== 'products'
	);

	const changeValue = (type: 'add' | 'remove', id: any) => {
		switch (type) {
			case 'add':
				props.input.onChange([...props.input.value, id]);
				break;
			case 'remove':
				props.input.onChange(props.input.value.filter((valueId: any) => id !== valueId));
		}
	};

	const customLayout = (label: string, input: JSX.Element) => (
		<Row gutter={[0, { xs: 0, sm: props.editMode ? 10 : 20 }]}>
			{!props.hideLabel && (
				<Col {...getColSpan(props.colspan)} className={classes.labelContainer}>
					<label className={classes.label} htmlFor={props.id}>
						{label}
					</label>
					<div className={classes.dotSeparator} />
				</Col>
			)}
			<Col {...getColSpan(props.colspan)} className={classes.readOnlyFieldContainer}>
				{props.editMode && paramsComponent ? paramsComponent : null}
				{props.editMode && input}
				<ul
					data-test={`${entity} list ${label}`}
					className={classes.storesList}
					style={{ marginBlockStart: props.editMode ? 16 : 0 }}
				>
					{props.input.value && props.input.value.length
						? props.input.value.map((id: string) => {
								const itemObj = cache[keyId][id];
								return (
									<li
										key={id}
										className={cx(classes.storesListItem, {
											[classes.listItemView]: !props.editMode,
										})}
										data-test={`${entity} list item ${itemObj ? itemObj[viewProp] : id}`}
									>
										<div className={classes.storeListLink}>
											<Link to={`/${linkCategory}/${id}`}>
												{itemObj
													? `${itemObj[viewProp]}${
															keyId === 'stores' && cache.stores[id]?.type === 'lavka'
																? ` (${cache[keyId][id]?.cluster})`
																: ''
														}`
													: id}
											</Link>
											{renderSelectedItemExtraInfo?.(id)}
										</div>
										{props.editMode && <DeleteButton onClick={() => changeValue('remove', id)} />}
									</li>
								);
							})
						: !props.editMode && (
								<p className={classes.valueOnViewMode} data-test={`${entity} list item ${label}`}>
									<EmptyValue />
								</p>
							)}
				</ul>
			</Col>
		</Row>
	);

	return (
		<div className={inputWrapperClassName}>
			<SelectWithSearch
				customLayout={customLayout}
				searchFunc={(value: string) => searchFunction(value, cancelTokenSource.current)}
				onSelect={(id: string) => (!props.input.value.includes(id) ? changeValue('add', id) : null)}
				virtual={false}
				{...props}
				input={{
					...props.input,
					value: valueString,
					onChange: () => {},
				}}
			/>
		</div>
	);
};

export default MultipleEntitySelect;
