import type { Entity, Id } from '~cache/cache';
import { useCacheStore } from '~cache/cache';
import { isNotNullish } from '~shared/utils/isNotNullish';
import type { Products } from '~types/products';

export const getProductWeight = (product: Products.Product | undefined): number | undefined => {
	if (product?.vars?.imported) {
		const { brutto_weight, netto_weight } = product.vars.imported;
		return brutto_weight ?? netto_weight;
	}
	return undefined;
};

export const getItemWeight = (item: Products.Item | undefined): number | undefined => {
	if (item?.data) {
		return item?.data?.weight;
	}
	return undefined;
};

async function refreshProducts(entity: Entity<'products'>, isExp?: boolean) {
	await useCacheStore.getState().refreshData('products', entity, isExp);
	const data = useCacheStore.getState().products?.data ?? {};

	return (Array.isArray(entity?.ids) ? entity?.ids : [entity?.ids] ?? [])
		.map((id: Id) => data[id ?? ''])
		.filter(isNotNullish);
}

export async function fetchProductsData(entity: Entity<'products'>, isExp?: boolean) {
	return {
		data: await refreshProducts(entity, isExp),
	};
}
