import { simpleHash } from '~shared/utils/simpleHash';

import { avatarColors } from './constants';

export const avatarBackgroundColor = (id: string, isRover?: boolean | null, isTaxi?: boolean) => {
	if (isRover) {
		return '#fc3f1d';
	}

	if (isTaxi) {
		return '#fce000';
	}

	return avatarColors[simpleHash(id) % avatarColors.length];
};
