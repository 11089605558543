import { notification } from '@lavka/ui-kit';
import { useTranslation } from 'react-i18next';

import api from '~/api';
import ModalComponent from '~shared/components/ModalComponent';

const UnAssignMentee = ({
	menteeId,
	visible,
	setModalUnAssignMentee,
	updateRerenderKey,
}: {
	menteeId: string;
	setModalUnAssignMentee: (value: string) => void;
	visible: boolean;
	updateRerenderKey: () => void;
}) => {
	const [t] = useTranslation();

	const onSubmit = async () => {
		try {
			await api.users.mentor_assign({ user_id: menteeId, mentor_id: null });
			updateRerenderKey();
		} catch {
			notification.error({
				message: t('Не удалось удалить подопечного'),
			});
		} finally {
			setModalUnAssignMentee('');
		}
	};

	return (
		<ModalComponent
			title={t('Вы точно хотите удалить подопечного из списка?')}
			open={visible}
			onOk={onSubmit}
			onCancel={() => {
				setModalUnAssignMentee('');
			}}
			okText={t('Применить')}
			destroyOnClose={true}
			width={600}
		>
			{t('Возможно, придётся выбрать нового наставника')}
		</ModalComponent>
	);
};

export default UnAssignMentee;
