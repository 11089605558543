import { notification } from '@lavka/ui-kit';
import dayjs from 'dayjs';
import type { FieldValues } from 'react-hook-form/dist/types/fields';
import type { UseFormReset } from 'react-hook-form/dist/types/form';

import api from '~/api';
import type { User } from '~types/user';

export const assign = async ({
	userId,
	mentorId,
	updateRerenderKey,
	reset,
	setModalVisible,
	notificationText,
}: {
	userId: string;
	mentorId: string;
	notificationText: { success: string; error: string };
	updateRerenderKey: () => void;
	reset: UseFormReset<FieldValues>;
	setModalVisible: (value: boolean) => void;
}) => {
	if (!mentorId) {
		reset();
		setModalVisible(false);
		notification.error({
			message: notificationText.error,
		});
		return;
	}
	try {
		await api.users.mentor_assign({ user_id: userId, mentor_id: mentorId });
		notification.success({
			message: notificationText.success,
		});
	} catch {
		notification.error({
			message: notificationText.error,
		});
	} finally {
		updateRerenderKey();
		reset();
		setModalVisible(false);
	}
};

export const getPotentialMentors = (usersData: User.User[] | undefined) => {
	return usersData
		?.filter(
			(exec) =>
				['executer', 'store_admin', 'vice_store_admin'].includes(exec.role) &&
				dayjs(exec?.created).add(45, 'day').isBefore(dayjs())
		)
		.sort((a, b) => {
			if (a.role === 'executer') {
				return -1;
			}

			if (a.role === 'vice_store_admin' && b.role === 'store_admin') {
				return -1;
			}
			return 0;
		});
};
