import { memo } from 'react';

import type { CourierStatuses } from '~server-types/supply/doc/api/models/courier';
import type { SelectOption } from '~shared/components/SimpleFields/PersonalDataSelect/utils';

import { useStyles } from './styles';

interface Props {
	option: SelectOption;
}

export const CourierOption = memo(({ option }: Props) => {
	const { classes, cx } = useStyles();

	return (
		<span
			className={cx({
				[classes.unactive]: (['disabled', 'removed'] as Exclude<CourierStatuses, 'active'>[]).includes(option.status),
			})}
		>
			{option.label}
		</span>
	);
});
