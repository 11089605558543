import { notification } from '@lavka/ui-kit';
import { useState } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import api from '~/api';
import { useCache } from '~cache/useCache';
import { ReactComponent as PencilRegular } from '~images/icons/pencil-regular.svg';
import ModalComponent from '~shared/components/ModalComponent';
import Icon from '~shared/utils/icons';
import type { User } from '~types/user';
import { useUser } from '~zustand/userData';

import { errorsCode } from '../../../../constants/errorsCode';
import { useStyles } from '../../styles';
import ChangeOrderUsersForm from './ChangeOrderUsersForm';

interface Props {
	orderId: string;
	users: string[];
	onlyChange?: boolean;
}

const ChangeOrderUsers = ({ orderId, users, onlyChange = false }: Props) => {
	const [t] = useTranslation();
	const { classes } = useStyles();
	const [showModal, toggleModal] = useState<boolean>(false);
	const user = useUser();

	const form = useForm<User.AdvancedUser>({});
	const { handleSubmit, control } = form;
	const userId = useWatch({ control, name: 'user_id' });

	useCache({
		userExecutors: [user.user_id],
	});

	const sendForm = async (values: User.AdvancedUser) => {
		try {
			await api.orders.setUsers(
				{
					order_id: orderId,
					users: values.add_user && !onlyChange ? [...users, values.user_id] : [values.user_id],
				},
				{
					disableDefaultNotification: [400],
				}
			);

			notification.success({
				message: t('Исполнители изменены'),
			});
			toggleModal(false);
		} catch (error) {
			notification.error({
				message: error?.data?.code ? errorsCode[error.data?.code] : t('Не удалось изменить исполнителей'),
			});
		}
	};

	return (
		<>
			<div onClick={() => toggleModal(true)} data-test="change_user_button" className={classes.blockEditBtn}>
				<Icon component={PencilRegular} className={classes.blockEditBtn} />
			</div>
			<ModalComponent
				className={`data-test-change-order-user-modal-${showModal ? 'visible' : 'hidden'}`}
				title={t('Изменить исполнителя документа')}
				open={showModal}
				onOk={handleSubmit(sendForm)}
				okButtonProps={{
					disabled: userId == null,
					className: 'data-test-submit-change-order-user',
				}}
				onCancel={() => toggleModal(false)}
			>
				<ChangeOrderUsersForm hookForm={form} onSubmit={sendForm} onlyChange={onlyChange} />
			</ModalComponent>
		</>
	);
};

export default ChangeOrderUsers;
