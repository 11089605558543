import { Alert, Button, Collapse } from 'antd';
import dayjs from 'dayjs';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { ReactComponent as TeamIcon } from '~images/icons/team.svg';
import { ReactComponent as AddIcon } from '~images/icons/user-add.svg';
import AssignMentee from '~shared/components/MenteeMentorField/AssignMentee';
import { MILESTONES } from '~shared/components/MenteeMentorField/const';
import UnAssignMentee from '~shared/components/MenteeMentorField/UnAssignMentee';
import { DeleteOutlined } from '~shared/utils/icons';
import userName from '~shared/utils/userName';
import type { User } from '~types/user';
import { useCheckPermit } from '~zustand/userData';

import { useStyles } from './styles';

const { Panel } = Collapse;

const MentorsField = ({
	user,
	menteesData,
	usersData,
	updateRerenderKey,
}: {
	user: Partial<User.User>;
	menteesData: Partial<User.User>[];
	usersData: Partial<User.User>[];
	updateRerenderKey: () => void;
}) => {
	const { classes } = useStyles();
	const [visible, setModalVisible] = useState(false);
	const [menteeToUnAssign, setMenteeToUnAssign] = useState('');
	const [t] = useTranslation();

	const currentMentees = menteesData.filter(
		(mentee) => dayjs().isBefore(dayjs(mentee?.vars?.mentoring_until).add(1, 'day')) && mentee.status === 'active'
	);

	const exMentees = menteesData.filter(
		(mentee) => dayjs().isAfter(dayjs(mentee?.vars?.mentoring_until).add(1, 'day')) || mentee.status !== 'active'
	);

	const usersList = usersData.filter((exec) => {
		const isNotSelf = exec.user_id !== user.user_id;
		const isNotMentoredBySelf = exec.mentor_id !== user.user_id;
		const isExec = ['executer_junior', 'executer'].includes(exec.role);
		const canReassign =
			exec.vars?.mentoring_until &&
			dayjs(exec.vars?.mentoring_since).add(MILESTONES.canReAssign, 'day').isAfter(dayjs());
		const canAssign =
			!exec.vars?.mentoring_until && dayjs(exec.created).add(MILESTONES.canAssign, 'day').isAfter(dayjs());

		return isNotSelf && isExec && (canAssign || canReassign) && isNotMentoredBySelf;
	});
	const assignPermit = useCheckPermit('users_save');
	return (
		<>
			<UnAssignMentee
				visible={!!menteeToUnAssign}
				setModalUnAssignMentee={setMenteeToUnAssign}
				menteeId={menteeToUnAssign}
				updateRerenderKey={updateRerenderKey}
			/>
			<AssignMentee
				userId={user.user_id}
				usersData={usersList}
				visible={visible}
				setModalVisible={setModalVisible}
				updateRerenderKey={updateRerenderKey}
			/>
			<div className={classes.container}>
				<div className={classes.headerContainer}>
					<div className={classes.header}> {t('Подопечные-новички')}</div>
					<Button className={classes.addButton} type="primary" ghost onClick={() => setModalVisible(true)}>
						<AddIcon />
						{t('Добавить')}
					</Button>
				</div>
				<div>
					<Alert
						className={classes.alert}
						icon={<TeamIcon height={16} width={16} className={classes.alertIcon} />}
						message={
							<>
								<div className={classes.alertHeader}>{t('Кладовщик - наставник')}</div>
								<div className={classes.alertText}>{t('Может помогать кладовщикам-новичкам адаптироваться')}</div>
							</>
						}
						type="info"
						showIcon
						closable
					/>
					{currentMentees?.map((mentee) => {
						return (
							<div key={mentee.user_id} className={classes.menteeContainer}>
								<div>
									<Link to={`/stores/${mentee.store_id}/users/${mentee.user_id}`}>{userName(mentee)}</Link>
									<div className={classes.menteeTime}>
										{t('Подопечный до: {{date}}', { date: dayjs(mentee.vars?.mentoring_until).format('DD.MM.YYYY') })}
									</div>
								</div>
								<Button
									disabled={!assignPermit}
									ghost
									shape="circle"
									icon={<DeleteOutlined />}
									style={{ border: 'none' }}
									className={classes.btnIcon}
									onClick={() => {
										setMenteeToUnAssign(mentee.user_id!);
									}}
								/>
							</div>
						);
					})}
				</div>
				{!!exMentees?.length && (
					<Collapse className={classes.containerCollapse}>
						<Panel
							key="Panel"
							header={<span className={classes.collapseHeader}>{t('Бывшие подопечные')}</span>}
							className={classes.containerCollapse}
						>
							{exMentees?.map((mentee) => {
								return (
									<div key={mentee.user_id} className={classes.menteeContainer}>
										<Link to={`/stores/${mentee.store_id}/users/${mentee.user_id}`}>{userName(mentee)}</Link>
										<div className={classes.menteeTime}>
											{t('Подопечный c {{dateFrom}} по {{dateTo}}', {
												dateTo: dayjs(mentee.vars?.mentoring_until).format('DD.MM.YYYY'),
												dateFrom: dayjs(mentee.vars?.mentoring_since).format('DD.MM.YYYY'),
											})}
										</div>
									</div>
								);
							})}
						</Panel>
					</Collapse>
				)}
			</div>
		</>
	);
};

export default MentorsField;
