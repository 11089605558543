import { Col, Row } from 'antd';
import { useEffect } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { Link } from 'react-router-dom';

import i18n from '~/i18n';
import type { Entity } from '~cache/cache';
import { useCache } from '~cache/useCache';
import { checkProjectsFields } from '~constants/dataPageFields/checkProjectsFields';
import { getField } from '~constants/dataPageFields/fieldHelper';
import type * as FieldTypes from '~constants/dataPageFields/types';
import { accountingTypesNames } from '~server-types/doc/api/models/product';
import DataSection from '~shared/components/DataForm/DataSection';
import { useStyles as useFormStyles } from '~shared/components/DataForm/styles';
import { createCustomI18n } from '~shared/components/DataLanguageSelector/i18n';
import { useStyles as useFieldStyles } from '~shared/components/Fields/styles';
import { getColSpan } from '~shared/components/Fields/utils';
import { Field } from '~shared/components/Forms';
import { Regrading } from '~shared/components/Regrading';
import { productTitles } from '~shared/components/Regrading/utils';
import useLoadData from '~shared/hooks/useLoadData';
import { getProductGroupChildren } from '~zustand/productGroupChildren';
import { useCheckExp, useUser } from '~zustand/userData';

import { fetchProductsData } from '../../../../components/ProductsPage/utils';
import { useStyles } from '../../styles';
import type { CheckProjectFormValues } from '../types';

interface Props {
	addMode?: boolean;
	editMode?: boolean;
}

export const fields = ['products.product_id', 'products.product_group_id', 'products.regrading_map'];

const colspan = {
	xl: { span: 6 },
	lg: { span: 8 },
	sm: { span: 12 },
	xs: { span: 12 },
};

const field = (key: string) => getField(checkProjectsFields, i18n.t('Проект ЛИ'), key);

export const ProductSection = ({ addMode, editMode = false }: Props) => {
	const form = useFormContext<CheckProjectFormValues>();
	const formValues = form.watch();

	const cache = useCache({
		products: [],
	});
	const { classes: commonClasses } = useFieldStyles();
	const { classes: formClasses } = useFormStyles({});
	const { classes } = useStyles();
	const isExpSeparateDataLang = useCheckExp('exp_separate_data_lang');
	const user = useUser();
	const customI18n = createCustomI18n({ lng: user?.data_language });

	useEffect(() => {
		getProductGroupChildren();
	}, [editMode]);

	useLoadData(
		() =>
			fetchProductsData(
				{
					ids: [],
					_fields: ['long_title', 'title'] as keyof Entity<'products'>['_fields'],
				},
				isExpSeparateDataLang
			),
		[customI18n?.options?.lng]
	);

	const checkProjectType = useWatch({
		control: form.control,
		name: 'check_project_type',
		defaultValue: 'schedule',
	});

	// Делаем руками без conditions, чтобы сохранять значения между переключением типов
	const productIdField = field('products.product_id') as FieldTypes.MultipleProductField | undefined;
	if (productIdField) {
		productIdField.hidden = checkProjectType === 'regrading';
		productIdField.renderSelectedItemExtraInfo = (id) => {
			const typeAccounting = cache.products[id]?.type_accounting;

			return (
				<div className={classes.productTypeAccounting}>{typeAccounting && accountingTypesNames[typeAccounting]}</div>
			);
		};
	}

	const productGroupIdField = field('products.product_group_id');
	if (productGroupIdField) {
		productGroupIdField.hidden = checkProjectType !== 'schedule';
	}

	const regradingMapField = field('products.regrading_map');
	if (regradingMapField) {
		regradingMapField.hidden = checkProjectType !== 'regrading';
		regradingMapField.element = (
			<Field
				key="regrading"
				name="products.regrading_map"
				component={Regrading}
				addMode={addMode}
				editMode={editMode}
				colspan={24}
			/>
		);
	}

	if (formValues.check_project_type === 'regrading' && !editMode) {
		return (
			<div className={formClasses.section}>
				{Object.values(formValues.products?.regrading_map[0]).map((productId, idx) => {
					const product = cache.products?.[productId ?? ''];
					return (
						<Row gutter={[0, { xs: 0, sm: 20 }]} key={productId}>
							<Col {...getColSpan(colspan)} className={commonClasses.labelContainer}>
								<label>{Object.values(productTitles)[idx]}</label>
								<span className={commonClasses.dotSeparator}></span>
							</Col>
							<Col {...getColSpan(colspan)}>
								<Link to={`/products/${productId}`}>{product?.long_title ?? product?.title ?? '—'}</Link>
							</Col>
						</Row>
					);
				})}
			</div>
		);
	}

	return (
		<DataSection
			section={{
				label: '',
				fields: checkProjectsFields[0].fields.filter((field) => fields.includes(field.key)),
			}}
			colspan={colspan}
			editMode={editMode}
			addMode={addMode}
		/>
	);
};
