import { Avatar, useDarkMode } from '@lavka/ui-kit';
import { Button, Divider, Popover, Switch } from 'antd';
import { memo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { useCache } from '~cache/useCache';
import { DataLanguageSelector } from '~shared/components/DataLanguageSelector';
import CustomI18nProvider from '~shared/components/DataLanguageSelector/CustomI18nProvider';
import { createCustomI18n } from '~shared/components/DataLanguageSelector/i18n';
import { logout } from '~shared/utils/loginFunctions';
import userName from '~shared/utils/userName';
import { useCheckExp, useUser, useUserDomainConfigs } from '~zustand/userData';
import { setUserAudio, setUserTheme, useUserAudio, useUserTheme } from '~zustand/userSettings';

import { UserPD } from '../../../../components/UsersPage/UserPD';
import { LanguageSelector } from '../../LanguageSelector';
import guestIcon from './guest.svg';
import { useStyles } from './styles';

const ChangeRoleLink = memo(() => {
	const [t] = useTranslation();
	const user = useUser();
	const domainConfigs = useUserDomainConfigs();
	const userIsGuest = user.role === 'authen_guest';
	const username = user.nick;

	if (!username || !domainConfigs.urls.assign_role || !domainConfigs.urls.revoke_role) {
		return null;
	}

	return userIsGuest ? (
		<a href={domainConfigs.urls.assign_role.replace('${username}', username)}>{t('Запросить новую роль')}</a>
	) : (
		<a href={domainConfigs.urls.revoke_role.replace('${username}', username)}>{t('Отозвать текущую роль')}</a>
	);
});

const UserSettings = () => {
	const [t] = useTranslation();
	const { classes } = useStyles();
	const [visible, toggleVisible] = useState(false);
	const user = useUser();
	const theme = useUserTheme();
	const audio = useUserAudio();
	const { setDarkMode } = useDarkMode();
	const { companies } = useCache({
		companies: {
			ids: user.company_id,
			_fields: ['title'],
			skip: !visible,
		},
	});

	const customI18 = createCustomI18n();

	const userFullname = userName(user);
	const company = companies[user.company_id ?? ''];
	const isExpSeparateDataLang = useCheckExp('exp_separate_data_lang');

	const content = (
		<div data-test={`settings menu ${visible ? 'visible' : 'hidden'}`}>
			{user.user_id && (
				<>
					<div className={classes.userData}>
						<Link to={`/users/${user.user_id}`} data-test="current user fullname">
							{userFullname || '—'}
						</Link>
					</div>
					<UserPD userId={user.user_id} type="email" buttonText={t('Показать email')} />
					<Divider className={classes.settingsDivider} />
					{company?.title && (
						<>
							<div className={classes.userData}>
								<p className={classes.settingLabel}>{t('Текущая компания')} </p>
								<Link to={`/companies/${user.company_id}`} data-test="current user company">
									{company.title}
								</Link>
							</div>
							<Divider className={classes.settingsDivider} />
						</>
					)}
				</>
			)}
			<h3 className={classes.settingsTitle}>{t('Настройки')}</h3>
			<div className={classes.settingRow}>
				<label className={classes.settingLabel}>{t('Язык интерфейса')}</label>
				<LanguageSelector />
			</div>
			{isExpSeparateDataLang ? (
				<div className={classes.settingRow}>
					<label className={classes.settingLabel}>{t('Язык данных')}</label>
					<CustomI18nProvider customI18n={customI18}>
						<DataLanguageSelector />
					</CustomI18nProvider>
				</div>
			) : null}
			<div className={classes.settingRow}>
				<label className={classes.settingLabel}>{t('Темная тема')}</label>
				<Switch
					onChange={(isDark) => {
						setUserTheme(isDark ? 'dark' : 'light');
						setDarkMode(isDark);
					}}
					checked={theme === 'dark'}
				/>
			</div>
			{user.user_id && (
				<>
					<div className={classes.settingRow}>
						<label className={classes.settingLabel}>{t('Звуки диспетчерской')}</label>
						<Switch onChange={(isAudioOn) => setUserAudio(isAudioOn)} checked={audio} />
					</div>
					<ChangeRoleLink />
					<Divider className={classes.settingsDivider} />
					<Button
						danger
						ghost
						onClick={() => logout(false, '/login')}
						className={classes.logoutBtn}
						data-test="logout btn"
					>
						{t('Выйти из профиля')}
					</Button>
				</>
			)}
		</div>
	);

	return (
		<Popover
			trigger="click"
			placement="bottom"
			open={visible}
			onOpenChange={() => toggleVisible(!visible)}
			content={content}
			overlayClassName={classes.popover}
		>
			<Avatar
				text={userFullname}
				imageUrl={userFullname ? undefined : guestIcon}
				data-test="settings button"
				className={classes.avatar}
			/>
		</Popover>
	);
};

export default memo(UserSettings);
