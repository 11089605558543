import { makeStyles } from '~styles/theme';

export const useStyles = makeStyles()(() => ({
	header: {
		fontSize: '20px',
		fontWeight: '500',
		lineHeight: '28px',
	},
	headerContainer: {
		display: 'flex',
		justifyContent: 'space-between',
		marginBlockEnd: '16px',
	},
	container: {
		borderRadius: '12px',
		padding: '16px',
		border: '1px solid #F2F4F5',
		boxShadow: '0px 2px 5px rgba(0, 34, 102, 0.05)',
	},
	addButton: {
		border: 'none',
		fontSize: '14px',
	},
	menteeContainer: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'baseline',
		borderBlockEnd: '1px solid #F5F5F5',
		paddingBlock: '10px',
		backgroundColor: 'inherit',
	},
	mentorContainer: {
		borderBlockEnd: 'none',
	},
	noMentor: {
		color: '#8C8C8C',
		padding: 0,
	},
	containerCollapse: {
		border: 'none !important',
		backgroundColor: '#FAFAFA',
		borderRadius: '8px!important',
		'.ant-collapse-content-active': {
			border: 'none !important',
		},
		'.ant-collapse-content': {
			backgroundColor: 'inherit',
		},
	},
	menteeTime: {
		color: '#8C8C8C',
		fontSize: '12px',
		lineHeight: '14px',
	},
	menteeName: {
		color: '#1890FF',
		fontWeight: 500,
		fontSize: '14px',
		lineHeight: '16px',
	},
	btnIcon: {
		alignSelf: 'center',
		svg: { color: '#8C8C8C' },
	},
	collapseHeader: {
		fontSize: '12px',
		lineHeight: '14px',
		paddingBlockEnd: 0,
		color: '#8C8C8C',
		'.ant-collapse-content-box': {
			paddingBlockStart: 0,
		},
	},
	alert: {
		display: 'flex',
		'.ant-alert-close-icon': {
			marginBlockEnd: 'auto',
			marginBlockStart: '3px',
		},
		marginBlockEnd: '15px',
	},
	alertIcon: {
		marginBlockEnd: 'auto',
	},
	alertHeader: {
		fontWeight: 500,
		fontSize: '14px',
		lineHeight: '16px',
	},
	alertText: {
		marginBlockStart: '3px',
		fontSize: '14px',
		lineHeight: '16px',
	},
	selectItem: {
		display: 'flex',
		justifyContent: 'space-between',
	},
}));
