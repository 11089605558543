import { notification } from '@lavka/ui-kit';
import { Select } from 'antd';
import { useTranslation } from 'react-i18next';

import api from '~/api';
import { useCache } from '~cache/useCache';
import { languageNames, languages } from '~i18n/consts';
import type { Language } from '~i18n/types';
import { getUserData, useUser } from '~zustand/userData';

import { useStyles } from './styles';

export const DataLanguageSelector = ({ dataTest = 'data language selector' }: { dataTest?: string }) => {
	const { classes } = useStyles();
	const [t, i18n] = useTranslation();

	const user = useUser();
	const cache = useCache({
		companies: [user.company_id],
	});

	const dataLanguages = cache.companies[user.company_id ?? '']?.data_languages ?? languages;

	const changeLanguage = async (dataLanguage: Language) => {
		try {
			if (user.user_id) {
				await api.profile.options_save({
					data_language: dataLanguage,
				});
			}

			await getUserData();
			await i18n.changeLanguage(dataLanguage.replace('_', '-'));

			notification.success({
				message: t('Язык данных изменен'),
			});
		} catch {
			notification.error({
				message: t('Не удалось изменить язык данных'),
			});
		}
	};

	return (
		<Select
			size="small"
			className={classes.select}
			onChange={changeLanguage}
			data-test={dataTest}
			value={user?.data_language}
			popupClassName={`data-test-select-dropdown-${dataTest?.replace(/\s/g, '-')}`}
		>
			{dataLanguages.map((language) => (
				<Select.Option key={language} data-test={`language option ${language}`} value={language}>
					{languageNames(language)}
				</Select.Option>
			))}
		</Select>
	);
};
