/* eslint-disable max-lines */

import { zodResolver } from '@hookform/resolvers/zod';
import { notification, Spin } from '@lavka/ui-kit';
import type { AxiosResponse } from 'axios';
import dayjs from 'dayjs';
import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import { useNavigate, useParams } from 'react-router-dom';
import type { z } from 'zod';

import api from '~/api';
import { useCache } from '~cache/useCache';
import { tabletMaxWidth } from '~constants/breakPoints';
import { getDefaultValues, getField } from '~constants/dataPageFields/fieldHelper';
import type { MultipleSelectField, SelectField, TextField as TextFieldType } from '~constants/dataPageFields/types';
import { userFields } from '~constants/dataPageFields/userPageFields';
import CodeCard from '~shared/components/CodeCard';
import DataForm from '~shared/components/DataForm';
import DataFormControls from '~shared/components/DataForm/DataFormControls';
import TextField from '~shared/components/Fields/TextField';
import { Field } from '~shared/components/Forms';
import MenteeMentorField from '~shared/components/MenteeMentorField';
import { MILESTONES } from '~shared/components/MenteeMentorField/const';
import { useAsyncEffect } from '~shared/hooks/useAsyncEffect';
import useEditMode from '~shared/hooks/useEditMode';
import useLoadData from '~shared/hooks/useLoadData';
import useRerender from '~shared/hooks/useRerender';
import useSendingHelpers from '~shared/hooks/useSendingHelpers';
import { useSetTitle } from '~shared/hooks/useSetTitle';
import useUuid from '~shared/hooks/useUuid';
import { areArraysIntersect } from '~shared/utils/arrayDiff';
import { mapFields } from '~shared/utils/mapFields';
import { rusToLatin } from '~shared/utils/rusToLatin';
import userName from '~shared/utils/userName';
import type { User } from '~types/user';
import {
	checkExp,
	useCheckExp,
	useCheckPermit,
	useCheckSubPermitGroup,
	useUser,
	useUserPermits,
	useUserStore,
} from '~zustand/userData';

import { COLSPAN } from '../constants';
import MenteeAlert from './MenteeAlert';
import { getSchema } from './schema';
import { UserPdField } from './UserPdField';
import { userNameFields } from './utils';

interface Props {
	editMode?: boolean;
	addMode?: boolean;
	idFromProps?: string;
	inModal?: boolean;
}

const UsersDataPage = ({ addMode = false, editMode = false, inModal, idFromProps }: Props) => {
	const [t] = useTranslation();
	const navigate = useNavigate();
	const params = useParams<{ user_id: string; store_id: string }>();
	const userId = idFromProps || params.user_id!;
	const storeId = params.store_id;
	const sendingHelpers = useSendingHelpers();
	const updateOnlyUser = useUserStore((state) => state.updateOnlyUser);
	const [currentPermits, setCurrentPermits] = useState<
		{
			name: string;
			value: boolean;
		}[]
	>();
	const isTabletOrMobile = useMediaQuery({
		query: `(max-width: ${tabletMaxWidth}px)`,
	});
	const externalId = useUuid([addMode]);
	const { rerenderKey, updateRerenderKey } = useRerender();

	const editUserData = useLoadData(
		() => api.users.load({ user_id: userId }, { strict: true }),
		[userId, editMode, addMode, rerenderKey],
		!userId || addMode
	);
	const editUser = editUserData?.data?.result;
	const user = useUser();
	const isPermitStoresSeek = useCheckPermit('stores_seek');
	const isPermitUsersSave = useCheckPermit('users_save');
	const isPermitAllowSaveProtected = useCheckPermit('allow_save_protected');
	const permits = useUserPermits();
	const isQrDynamic =
		currentPermits?.some((permit) => permit.name === 'exp_dynamic_qr_only' && permit.value) &&
		editUser?.store_id &&
		checkExp('exp_dynamic_qr_only');

	const isExpSeparateDataLang = useCheckExp('exp_separate_data_lang');

	const userQrData = useLoadData(
		() => api.users.load({ user_id: userId, _fields: ['qrcode', 'qrcode_expired'] }, { strict: true }),
		[userId, addMode, isQrDynamic],
		!userId || addMode || !isQrDynamic
	);
	const userQr = userQrData?.data?.result;

	const cache = useCache({
		companies: [editUser?.company_id, user.company_id],
		stores: editUser?.store_id,
	});

	const editUserCompany = cache.companies[editUser?.company_id ?? ''];
	const editUserStore = cache.stores[editUser?.store_id ?? ''];
	const userCompany = cache.companies[user.company_id ?? ''];

	let mappedUserFields = userFields;

	const phoneField = getField(mappedUserFields, t('Контакты'), 'phone') as TextFieldType | undefined;
	if (phoneField) {
		phoneField.element = (
			<Field
				name={phoneField.key}
				key={phoneField.key}
				label={phoneField.label}
				canEdit={phoneField.canEdit}
				editMode={editMode}
				addMode={addMode}
				userId={userId}
				pdType="phone"
				component={UserPdField}
				initialComponent={TextField}
			/>
		);
	}

	const emailField = getField(mappedUserFields, t('Контакты'), 'email') as TextFieldType;
	if (emailField) {
		emailField.element = (
			<Field
				name={emailField.key}
				key={emailField.key}
				label={emailField.label}
				canEdit={emailField.canEdit}
				editMode={editMode}
				addMode={addMode}
				userId={userId}
				pdType="email"
				component={UserPdField}
				initialComponent={TextField}
			/>
		);
	}

	useEditMode(editMode || addMode);

	const userHasRolesPermit = permits.some((permit) => permit.name === 'roles_permits' && permit.value);

	useAsyncEffect(async () => {
		if (userHasRolesPermit) {
			try {
				if (editUser?.role) {
					const { data } = await api.users.permits({
						roles: [editUser?.role],
					});
					// @ts-expect-error
					setCurrentPermits(data.result[0].permits);
				}
			} catch {
				notification.error({
					message: t('Не удалось получить доступ к редактированию кластеров'),
				});
			}
		}
	}, [editUser?.role, userHasRolesPermit]);

	// В общем виде правила разрешения редактирования сотрудников выглядят следующим образом:
	// 1) Роль сотрудника можно редактировать, только если у сотрудника role_editable === true, но только если его provider !== internal;
	// 2) Одного франчайзи может редактировать только другой франчайзи.
	// 3) Франчайзи может менять роль авторизованному гостю. Сотрудник яндекса - не может.
	// 4) ФИО, ник, телефон и имэйл сотрудников яндекса можно менять только если их provider === internal (кладовщики, курьеры и т.д.)
	//
	// Ниже переменные fieldsUnlocked, currentUserHasEditableRole, userIsEditableAuthenGuest и canEditPersonalFields управляют этой логикой.

	// Разрешаем одному франчайзи редактировать другого франчайзи или сотрудника без привязки к компании, но только если в обоих случаях сотрудник не гость.

	const fieldsUnlocked = useMemo(() => {
		if (!editUserData.loading) {
			return (
				(editUserCompany ? editUserCompany.ownership === 'franchisee' : true) &&
				userCompany?.ownership === 'franchisee' &&
				editUser?.role !== 'authen_guest'
			);
		}
	}, [editUserData.loading, user]);

	// Проверяем можно ли редактировать роль сотрудника
	const currentUserHasEditableRole = editUser?.role_editable && fieldsUnlocked && editUser?.provider !== 'internal';

	// Проверяем является ли сотрудник гостем, а редактор - франчайзи
	const userIsEditableAuthenGuest = editUser?.role === 'authen_guest' && userCompany?.ownership === 'franchisee';

	const sendForm = async (data: z.infer<ReturnType<typeof getSchema>>) => {
		const values = { ...data };

		sendingHelpers.toggleSending(true);

		values.email = values.email?.toLowerCase();
		if (!values.nick) {
			values.nick = rusToLatin(
				[values.last_name, values.first_name, values.middle_name].filter(Boolean).join(' '),
				'_'
			);
		}

		// Если поля не изменяли, то не отправляем их на бэк в режиме редактирования
		if (!areArraysIntersect(userNameFields, dirtyFields)) {
			userNameFields.map((field) => {
				// @ts-ignore
				delete values[field];
			});
		}

		try {
			if (
				(values.role === 'store_admin' || values.role === 'kitchen_manager') &&
				values.role !== editUser?.role &&
				!editUser?.store_id &&
				!values.store_id
			) {
				values.store_id = user.store_id!;
			}

			values.phone = values.phone?.replaceAll(/-| |\(|\)/g, '');

			let response: AxiosResponse<{ result: User.User }>;
			if (addMode) {
				response = await api.users.create({
					...(values as User.UserCreate),
					external_id: externalId,
				});
			} else {
				response = await api.users.save(values as User.UserUpdate);
			}

			const data = response.data.result;
			if (user.user_id === data?.user_id) {
				updateOnlyUser(data); // обновляем пользователя в Zustand-хранилище
			}
			notification.success({
				message: t('Пользователь сохранен'),
			});
			navigate(storeId ? `/stores/${storeId}/users/${data.user_id}` : `/users/${data.user_id}`);
		} catch {
			notification.error({
				message: t('Не удалось изменить данные сотрудника'),
			});
		} finally {
			sendingHelpers.toggleSending(false);
		}
	};

	const roleField = getField(mappedUserFields, t('Основное'), 'role') as SelectField | undefined;
	if (roleField) {
		if (addMode) {
			roleField.options = ['executer', 'stocktaker', 'kitchen_manager', 'picker'];
			// Добавить пользователя можно если выполняются оба условия:
			// - добавляемая роль входит в permit sub
			// - добавляемая роль имеет internal: true (параметр интернал не приходит на фронт, потому список доступных ролей захардкожен)
		} else if (!editUser?.email_pd_id || editUser?.provider === 'internal') {
			roleField.options = ['executer'];
		} else {
			roleField.options = (permits.find((permit) => permit.name === 'sub')?.value as string[]) ?? roleField.options;
		}

		roleField.canEdit = currentUserHasEditableRole || userIsEditableAuthenGuest;
	}

	const employeeNumber = getField(mappedUserFields, t('Основное'), 'employee_number');

	if (employeeNumber) {
		employeeNumber.canEdit =
			(editUserStore?.users_manage === 'internal' ||
				(!editUserStore?.users_manage && editUserCompany?.users_manage === 'internal')) &&
			isPermitAllowSaveProtected;
	}

	const statusField = getField(mappedUserFields, t('Основное'), 'status') as SelectField | undefined;

	if (statusField) {
		if (editUser?.provider === 'auth-api') {
			statusField.canEdit = false;
			statusField.tooltip = t('Изменение статуса пользователя доступно только в сервисе ID');
		} else {
			statusField.canEdit = true;
			statusField.tooltip = undefined;
		}
	}

	const clustersAllowField = getField(mappedUserFields, t('Другое'), 'clusters_allow') as MultipleSelectField;

	const isClustersAllowSubPermitGroupOfUsersFieldsEdit = useCheckSubPermitGroup(
		'group_user_edit_additional',
		'clusters_allow'
	);

	if (clustersAllowField && currentPermits) {
		const hasOutOfCompany = currentPermits.some((permit) => permit.name === 'out_of_company' && permit.value);
		//@ts-expect-error
		clustersAllowField.disabled = !isClustersAllowSubPermitGroupOfUsersFieldsEdit;
		clustersAllowField.searchDataProps = hasOutOfCompany ? {} : { company_id: editUser?.company_id };
	}

	useEffect(() => {
		if (userQr?.qrcode_expired) {
			const diff = dayjs(userQr.qrcode_expired).diff(dayjs(), 'second');
			const timeout = setTimeout(userQrData.req, (diff - 10) * 1000);
			return () => clearTimeout(timeout);
		}
	}, [userQr?.qrcode_expired, userQrData.req]);

	const qrcodeField = getField(mappedUserFields, t('Визитка'), 'qrcode');
	if (qrcodeField) {
		qrcodeField.element = (
			<Field
				name="change_barcode"
				barcodeId={editUser?.user_id}
				cardTitle={userName(editUser) || editUser?.nick}
				cardDataTest="user card"
				editMode={editMode}
				barcode={isQrDynamic ? userQr?.qrcode : editUser?.qrcode}
				additionalData={{
					store: editUserStore?.title ?? '—',
				}}
				component={CodeCard}
			/>
		);
	}
	const expMentorship = useCheckExp('exp_mentorship');

	const menteesData = useLoadData(
		//@ts-ignore
		() => api.users.mentor_assigned({ mentor_id: editUser?.user_id }),
		[rerenderKey, editUser?.user_id],
		!(editUser?.user_id && expMentorship)
	);

	const usersData = useLoadData(
		() => api.users.list({ store_id: editUser?.store_id, status: 'active' }),
		[editUser?.user_id],
		!editUser?.user_id
	);

	const userMentorData = useLoadData(
		() => api.users.load({ user_id: editUser?.mentor_id ?? '' }),
		[editUser?.mentor_id, rerenderKey],
		!(editUser?.mentor_id && expMentorship)
	);

	const mentorField = getField(mappedUserFields, t('Визитка'), 'mentee');
	if (mentorField && expMentorship) {
		mentorField.element = ['executer', 'vice_store_admin', 'store_admin', 'executer_junior'].includes(
			editUser?.role
		) ? (
			<Field
				name="mentee"
				component={() =>
					menteesData.loaded && usersData.loaded ? (
						<MenteeMentorField
							usersData={usersData.data?.results ?? []}
							menteesData={menteesData.data?.result ?? []}
							updateRerenderKey={updateRerenderKey}
							user={editUser ?? {}}
							usersMentor={userMentorData.data?.result}
						/>
					) : (
						<Spin />
					)
				}
			/>
		) : undefined;
	}

	const storeField = getField(mappedUserFields, t('Другое'), 'store_id');
	if (storeField) {
		storeField.canEdit = isPermitStoresSeek && editUser?.provider !== 'yandex-team';
	}

	const languageField = getField(mappedUserFields, t('Настройки'), 'lang') as MultipleSelectField;
	if (languageField) {
		languageField.options = editUserCompany?.languages;
	}

	const dataLanguageField = getField(mappedUserFields, t('Настройки'), 'data_language') as MultipleSelectField;
	if (dataLanguageField) {
		dataLanguageField.options = editUserCompany?.data_languages;
		dataLanguageField.hidden = !isExpSeparateDataLang;
	}

	if (addMode) {
		const availableFields = [
			'role',
			'email',
			'nick',
			'last_name',
			'first_name',
			'middle_name',
			'phone',
			'employee_number',
		];

		mappedUserFields = mapFields(mappedUserFields, (field) => {
			if (availableFields.includes(field.key)) {
				return { ...field, canEdit: true };
			}
			return undefined;
		});
	}

	let title = '';

	if (addMode) {
		title = t('Добавление сотрудника');
	} else if (editMode) {
		title = t('Редактирование сотрудника');
	} else if (!inModal) {
		title = t('Просмотр сотрудника');
	}

	useSetTitle(title);

	const lastName = getField(mappedUserFields, t('Основное'), 'last_name');
	const firstName = getField(mappedUserFields, t('Основное'), 'first_name');
	const middleName = getField(mappedUserFields, t('Основное'), 'middle_name');

	const nickname = getField(mappedUserFields, t('Основное'), 'nick');
	const canEditPersonalDataFields = fieldsUnlocked || editUser?.provider === 'internal' || addMode;

	[lastName, firstName, middleName, nickname, phoneField].forEach((field) => {
		if (field) {
			field.canEdit = canEditPersonalDataFields;
		}
	});

	if (emailField) {
		// email.canEdit = mayEditPersonalFields;
		// TODO: Если поле будет поддержано в ручке /api/admin/users/load при UserUpdateRequest нужно разблокировать поле и добавить email пользователя в api.users.save
		emailField.canEdit = addMode;
	}

	const [dirtyFields, setDirtyFields] = useState<string[]>([]);
	const form = useForm<z.infer<ReturnType<typeof getSchema>>>({
		defaultValues: getDefaultValues(mappedUserFields),
		values: editUser,
		resolver: zodResolver(getSchema(addMode, canEditPersonalDataFields, dirtyFields, isExpSeparateDataLang)),
	});

	const { handleSubmit, reset, formState } = form;

	useEffect(() => {
		setDirtyFields(Object.keys(formState.dirtyFields));
	}, [JSON.stringify(formState.dirtyFields)]);

	const neverHadAMentor =
		!editUser?.vars.mentoring_until &&
		dayjs(editUser?.created).add(MILESTONES.canBeMentoredBefore, 'day').isAfter(dayjs());
	const mentoringInProgress =
		!!editUser?.vars.mentoring_until && dayjs(editUser?.vars.mentoring_until).add(1, 'day').isAfter(dayjs());
	const isExec = ['executer', 'executer_junior'].includes(editUser?.role);
	const isActive = editUser?.status === 'active';
	const displayMenteeAlert = (neverHadAMentor || mentoringInProgress) && isExec && isActive;

	return (
		<DataFormControls
			addMode={addMode}
			editMode={editMode}
			inProgress={sendingHelpers.sending}
			backLink="/users"
			quitEditModeLink={
				isPermitUsersSave
					? storeId
						? `/stores/${storeId}/users${addMode ? '' : `/${userId}`}`
						: `/users${addMode ? '' : `/${userId}`}`
					: undefined
			}
			enterEditModeLink={
				isPermitUsersSave ? (storeId ? `/stores/${storeId}/users/edit/${userId}` : `/users/edit/${userId}`) : undefined
			}
			submit={handleSubmit(sendForm)}
			resetForm={reset}
			testSelectors={{
				back: 'users table back button',
				exitEdit: 'users table exit edit',
				enterEdit: 'users table enter edit',
				save: 'users table save',
			}}
			title={{
				edit: t('Редактирование сотрудника'),
				add: t('Добавление сотрудника'),
				view: t('Просмотр сотрудника'),
			}}
			showHeader={!inModal}
		>
			{displayMenteeAlert && expMentorship && <MenteeAlert editUser={editUser} />}
			<DataForm
				formFields={mappedUserFields}
				editMode={editMode}
				addMode={addMode}
				onSubmit={sendForm}
				loading={editUserData.loading}
				colspan={COLSPAN}
				dataTest={`form user profile ${editMode ? 'edit' : 'view'}`}
				twoColLayout={
					!isTabletOrMobile
						? [
								{
									colspan: {
										xl: { span: 16 },
										lg: { span: 16 },
										sm: { span: 16 },
										xs: { span: 16 },
									},
									sections: [t('Основное'), t('Контакты'), t('Другое'), t('Настройки')],
								},
								{
									colspan: {
										xl: { span: 8 },
										lg: { span: 8 },
										sm: { span: 8 },
										xs: { span: 8 },
									},
									sections: [t('Визитка')],
								},
							]
						: undefined
				}
				hookForm={form}
			/>
		</DataFormControls>
	);
};

export default UsersDataPage;
