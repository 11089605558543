import { Button, Radio, Tooltip } from 'antd';
import dayjs from 'dayjs';
import { type Dispatch, type SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as QuestionCircleSolid } from '~images/icons/question-circle-solid.svg';
import DatePicker from '~shared/components/DayJsComponents/DatePicker';
import Icon from '~shared/utils/icons';

import type { Period } from '../../../AnalyticsPage';
import type { PeriodState } from '../../healthTypes';
import ComparisonTypeSelect from '../PeriodControls/ComparisonTypeSelect';
import type { TickValue } from '.';
import { useStyles } from './styles';

const { RangePicker } = DatePicker;

// На беке для ручки api/report_data/health_metrics/analytics.py - ограничение 92 дня
const MAX_LIMIT_PERIOD = 92;

type Props = {
	period: Period;
	setPeriod: Dispatch<SetStateAction<Period>>;
	isDataByHour: boolean;
	tickValue: TickValue;
	setTickValue: Dispatch<SetStateAction<TickValue>>;
	loadGraphs: () => void;
	comparisonType: PeriodState['comparisonType'];
	setComparisonType: Dispatch<SetStateAction<PeriodState['comparisonType']>>;
	timezone?: string;
	timezoneMessage?: string;
};

const formatFromDateString = (date?: string, timezone?: string) =>
	dayjs(date).isValid() ? dayjs(date).tz(timezone) : null;

const FiltersHeader = ({
	period,
	setPeriod,
	isDataByHour,
	tickValue,
	setTickValue,
	comparisonType,
	setComparisonType,
	loadGraphs,
	timezone,
	timezoneMessage,
}: Props) => {
	const [t] = useTranslation();
	const { classes } = useStyles();

	return (
		<div className={classes.filterHeader}>
			<div className={classes.controlBlock}>
				<div className={classes.controlLabel}>{t('Период')}:</div>
				<RangePicker
					showTime={{ format: 'HH:mm' }}
					placeholder={[t('Начальная дата'), t('Конечная дата')]}
					className={classes.firstInput}
					onChange={(values) => {
						setPeriod({
							begin: values && values[0] ? values[0].format() : dayjs().format(),
							end: values && values[1] ? values[1]?.format() : dayjs().format(),
						});
					}}
					value={[
						formatFromDateString(period.begin, timezone),
						dayjs(period.end).isAfter(dayjs()) ? dayjs() : formatFromDateString(period.end, timezone),
					]}
					disabledDate={(date) => date.isBefore(dayjs().subtract(MAX_LIMIT_PERIOD, 'day')) || date.isAfter(dayjs())}
					format="DD.MM.YYYY HH:mm"
					data-test="analytics controls period"
				/>
				<Tooltip title={timezoneMessage}>
					<Icon component={QuestionCircleSolid} />
				</Tooltip>
			</div>
			<ComparisonTypeSelect
				comparisonType={comparisonType}
				onChange={setComparisonType}
				dataTest="analytics controls comparison"
			/>
			<Button type="primary" onClick={loadGraphs}>
				{t('Применить')}
			</Button>

			<div className={classes.tickSelectContainer}>
				<span>{t('Детализация')}</span>
				<Radio.Group value={tickValue} onChange={(e) => setTickValue(e.target.value)} buttonStyle="solid">
					<Radio.Button value={5} disabled={isDataByHour}>
						<span data-test="health tick switch 5">{t('{{minutes}} мин', { minutes: 5 })}</span>
					</Radio.Button>
					<Radio.Button value={15} disabled={isDataByHour}>
						<span data-test="health tick switch 15">{t('{{minutes}} мин', { minutes: 15 })}</span>
					</Radio.Button>
					<Radio.Button value={60}>
						<span data-test="health tick switch 60">{t('{{hours}} ч', { hours: 1 })}</span>
					</Radio.Button>
				</Radio.Group>
			</div>
		</div>
	);
};

export default FiltersHeader;
